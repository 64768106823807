import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  BulkCreateNfcRequest,
  CreateNfcRequest,
  UpdateNfcRequest,
} from '../types/api/nfc'
import { Nfc } from '../types/Nfc'

export const bulkCreateNfcApi = async (
  data: BulkCreateNfcRequest
): Promise<void> => {
  return api.post(`/nfc/bulk`, data)
}

export const createNfcApi = async (data: CreateNfcRequest): Promise<void> => {
  return api.post(`/nfc`, data)
}

export const updateNfcApi = async (
  id: string,
  data: UpdateNfcRequest
): Promise<void> => {
  return api.patch(`/nfc/${id}`, data)
}

export const getNfcListApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  customerId?: string
): Promise<ListDto<Nfc>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(
    `/nfc?page=${page}&limit=${limit}${customerQuery}${searchQuery}`
  )
}

export const getNfcApi = async (id: string): Promise<Nfc> => {
  return api.get(`/nfc/${id}`)
}

export const deleteNfcApi = async (id: string): Promise<void> => {
  return api.delete(`/nfc/${id}`)
}

export default { getNfcListApi }
