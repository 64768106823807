import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React from 'react'

function AlertWarningIcon() {
  return (
    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
      <ExclamationTriangleIcon
        className="h-12 w-12 text-yellow-400"
        aria-hidden="true"
      />
    </div>
  )
}

export default AlertWarningIcon
