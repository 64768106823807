import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { atom } from 'recoil'

export type AlertPropType = {
  icon?: JSX.Element
  title?: string
  desc?: string
  onClick?: () => Promise<void> | void
  confirm?: {
    confirmClick: () => Promise<void> | void
    cancelClick?: () => Promise<void> | void
  }
}

export const alertAtom = atom<AlertPropType | null>({
  key: 'alertAtom',
  default: null,
})

export type ToastPropType = {
  title?: string
  desc?: string
  duration?: number
  onClick?: () => Promise<void> | void
  confirm?: {
    confirmClick: () => Promise<void> | void
    cancelClick?: () => Promise<void> | void
  }
}

export const toastAtom = atom<ToastPropType | null>({
  key: 'toastAtom',
  default: null,
})

export default {}
