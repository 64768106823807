import Joi from 'joi'
import validator from '../lib/validate'

export default {}

export const NfcCreateValidation = validator().object({
  id: Joi.string().required(),
  name: Joi.optional(),
  customerId: Joi.optional(),
  type: Joi.optional(),
  placeId: Joi.optional(),
  documentId: Joi.optional(),
  contents: Joi.optional(),
})
