import { ToastPropType, toastAtom } from '../recoil/notification.recoil'
import { setRecoilExternalState } from './recoilExternalState'

export const showToast = (
  title: ToastPropType | string,
  props?: ToastPropType
) => {
  let newProps = props || ({} as ToastPropType)
  if (typeof title === 'string') {
    newProps.title = title
  } else {
    newProps = {
      ...props,
      ...title,
    }
  }
  newProps.duration = newProps.duration ?? 5000
  setRecoilExternalState(toastAtom, newProps)
}

export const hideToast = () => setRecoilExternalState(toastAtom, null)

export default {}
