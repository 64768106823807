import React, { Children, HTMLAttributes } from 'react'
import { classNames } from '../../helpers/common.helper'

type ManageListLayoutProps = HTMLAttributes<HTMLElement> & {
  title: string
  description?: string
  children?: React.ReactNode
  createButtonChildren?: React.ReactNode
  searchChildren?: React.ReactNode
  paginateChildren?: React.ReactNode
  tailChildren?: React.ReactNode
}

function ManageListLayout({
  title,
  description,
  children,
  createButtonChildren,
  searchChildren,
  paginateChildren,
  tailChildren,
  className,
}: ManageListLayoutProps) {
  return (
    <div className={classNames('py-6 px-4 sm:px-6 lg:px-8', className)}>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="text-lg font-medium leading-6 text-slate-900">
            {title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-slate-500">{description}</p>
        </div>

        {createButtonChildren && (
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {createButtonChildren}
          </div>
        )}
      </div>
      <div className="mt-8 flex flex-col">{children}</div>
      <div className="flex items-center justify-between bg-white py-3">
        {searchChildren}
        {paginateChildren}
      </div>
      {tailChildren}
    </div>
  )
}

export default ManageListLayout
