import { useEffect, useState } from 'react'
import React, { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteSurveyVersionApi, getSurveyVersionApi } from '../api/survey.api'
import Button from '../components/button/Button'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import useSurvey from '../hooks/survey.hook'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { SurveyVersion } from '../types/Survey'

function SurveyVersionView(): JSX.Element {
  const { id } = useParams()
  const [surveyVersion, setSurveyVersion] = useState<SurveyVersion>()
  const navigate = useNavigate()
  const { refreshVersions } = useSurvey()

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteSurveyVersionApi(id)
          refreshVersions()
          navigate('/survey-version')
        },
      },
    })
  }

  useEffect(() => {
    if (id) {
      getSurveyVersionApi(id).then((data) => {
        setSurveyVersion(data)
      })
    }
  }, [id])

  return (
    <ViewLayout
      title="현장조사 버전 상세"
      description="현장조사 버전을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/survey-version" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/survey-version/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="업체 이름">{surveyVersion?.customer?.name}</SideLabel>
      <SideLabel label="버전 이름">{surveyVersion?.name}</SideLabel>
    </ViewLayout>
  )
}
export default SurveyVersionView
