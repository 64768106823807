import { useEffect, useState } from 'react'
import React, { useParams, Link, useNavigate } from 'react-router-dom'
import { deleteAdminUserApi, getAdminUserApi } from '../api/admin-user.api'
import Button from '../components/button/Button'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { AdminUser } from '../types/User'

function AdminUserView(): JSX.Element {
  const { id } = useParams()
  const [adminUser, setAdminUser] = useState<AdminUser>()
  const navigate = useNavigate()

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteAdminUserApi(id)
          navigate('/admin-users')
        },
      },
    })
  }

  useEffect(() => {
    if (id) {
      getAdminUserApi(id).then((data) => {
        setAdminUser(data)
      })
    }
  }, [id])
  return (
    <ViewLayout
      title="관리자 상세"
      description="관리자나 매니저를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/admin-users" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/admin-users/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="이름">{adminUser?.name}</SideLabel>
      <SideLabel label="이메일"> {adminUser?.email}</SideLabel>
      <SideLabel label="타입">{adminUser?.roles[0]?.name}</SideLabel>
    </ViewLayout>
  )
}
export default AdminUserView
