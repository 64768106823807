import { atom, selector } from 'recoil'
import { getCustomersApi } from '../api/customer.api'
import { OptionProps } from '../components/select/Select'
import { RoleType } from '../types/Role'

export const customersSelector = selector<OptionProps[]>({
  key: 'userCreate-customersSelector',
  get: async () => {
    const customers = await getCustomersApi({ page: 1, limit: 100 }).then(
      (data) => {
        return data.items.map((item) => {
          return { text: item.name, value: item.id }
        })
      }
    )

    return customers
  },
})

export const selectedRoleAtom = atom<RoleType>({
  key: 'userCreate-selectedRoleAtom',
  default: RoleType.ADMIN,
})

export const selectedCustomerAtom = atom<string | undefined>({
  key: 'userCreate-selectedCustomerAtom',
  default: undefined,
})

export default {}
