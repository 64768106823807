import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React from 'react'

type PaginateProps = {
  total: number
  limit: number
  currentPage: number
  onChangePage: (page: number) => void
}

export default function Paginate({
  total,
  limit,
  currentPage,
  onChangePage,
}: PaginateProps) {
  const lastPage = Math.ceil(total / limit)
  const nextPage = currentPage + 1 <= lastPage ? currentPage + 1 : lastPage
  const prevPage = currentPage > 1 ? currentPage - 1 : 1
  const renderPages = () => {
    const result = []
    if (currentPage === 1 || currentPage === 2) {
      for (let i = 1; i <= 5; i += 1) {
        result.push(i)
        if (i >= lastPage) break
      }
    } else if (currentPage === lastPage - 1 || currentPage === lastPage) {
      for (let i = lastPage - 4; i <= lastPage; i += 1) {
        result.push(i)
        if (i >= lastPage) break
      }
    } else {
      for (let i = currentPage - 2; i <= currentPage + 2; i += 1) {
        result.push(i)
        if (i >= lastPage) break
      }
    }

    return result
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!total) return <></>

  return (
    <div className="sm:flex sm:flex-1 sm:items-center sm:justify-end">
      <div>
        <nav
          className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => onChangePage(prevPage)}
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
          >
            <span className="sr-only">이전</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          {currentPage >= 4 && (
            <button
              type="button"
              className="relative inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
            >
              ...
            </button>
          )}
          {/* Current: "z-10 bg-teal-50 border-teal-500 text-teal-600", Default: "bg-white border-slate-300 text-slate-500 hover:bg-slate-50" */}
          {renderPages().map((p) => {
            const className =
              p === currentPage
                ? 'relative z-10 inline-flex items-center border border-teal-500 bg-teal-50 px-4 py-2 text-sm font-medium text-teal-600'
                : 'relative inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50'
            return (
              <button
                key={p}
                type="button"
                onClick={() => onChangePage(p)}
                aria-current="page"
                className={className}
              >
                {p}
              </button>
            )
          })}
          {lastPage - currentPage >= 3 && (
            <button
              type="button"
              className="relative inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
            >
              ...
            </button>
          )}
          <button
            onClick={() => onChangePage(nextPage)}
            type="button"
            className="relative inline-flex items-center rounded-r-md border border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
          >
            <span className="sr-only">다음</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  )
}
