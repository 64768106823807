import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Nav from './Nav'

export default function Layout(): JSX.Element {
  return (
    <>
      <Nav />
      <div className="md:pl-64 flex flex-col flex-1">
        <Header />

        <main>
          <React.Suspense>
            <Outlet />
          </React.Suspense>
        </main>
      </div>
      {/* <Footer /> */}
    </>
  )
}
