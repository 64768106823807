import { api } from '../lib/api'
import { ListDto } from '../types/api/common'
import { CreatePlaceRequest, UpdatePlaceRequest } from '../types/api/place'
import { Place } from '../types/Place'

export const createPlaceApi = async (
  data: CreatePlaceRequest
): Promise<Place> => {
  return api.post(`/place`, data)
}

export const updatePlaceApi = async (
  id: string,
  data: UpdatePlaceRequest
): Promise<Place> => {
  return api.patch(`/place/${id}`, data)
}

export const getPlaceListApi = async (
  page: number,
  limit: number,
  customerId?: string
): Promise<ListDto<Place>> => {
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(`/place?page=${page}&limit=${limit}${customerQuery}`)
}

export const searchPlaceListApi = async (
  page: number,
  limit: number,
  search?: string,
  customerId?: string
): Promise<ListDto<Place>> => {
  const searchQuery = search ? `&search=${search}` : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(
    `/place/search?page=${page}&limit=${limit}${customerQuery}${searchQuery}`
  )
}

export const getPlaceChildrenApi = async (id?: string): Promise<Place> => {
  return api.get(`/place/${id}/children`)
}

export const getPlaceApi = async (id: string): Promise<Place> => {
  return api.get(`/place/${id}`)
}

export const deletePlaceApi = async (id: string): Promise<void> => {
  return api.delete(`/place/${id}`)
}

export default {}
