import Joi from 'joi'
import validator from '../lib/validate'
import { DocumentState, DocumentType } from '../types/Document'

export const DocumentCreateValidation = validator().object({
  customerId: Joi.string().required(),
  title: Joi.string().required(),
  contents: Joi.string().optional(),
  type: Joi.number().valid(...Object.values(DocumentType)),
  state: Joi.number().valid(...Object.values(DocumentState)),
  files: Joi.optional(),
})

export default {}
