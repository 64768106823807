import React from 'react'

interface IconProps {
  className?: string
}
export default function LogoIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 960 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M222.669 547.645C176.636 479.818 159.244 396.561 174.273 315.979C189.303 235.397 235.539 164.007 302.926 117.334C370.314 70.6617 453.402 52.4814 534.123 66.7471C614.844 81.0127 686.669 126.57 733.978 193.513C781.286 260.455 800.252 343.368 786.752 424.221C773.251 505.073 728.376 577.326 661.884 625.266C595.393 673.207 512.663 692.957 431.687 680.223C350.71 667.489 278.035 623.3 229.467 557.265L397.271 433.847C413.309 455.652 437.307 470.244 464.046 474.449C490.786 478.654 518.104 472.132 540.061 456.302C562.017 440.471 576.835 416.612 581.293 389.914C585.751 363.215 579.488 335.836 563.867 313.731C548.245 291.626 524.527 276.582 497.872 271.871C471.217 267.161 443.78 273.164 421.528 288.576C399.276 303.988 384.008 327.562 379.045 354.171C374.082 380.78 379.826 408.273 395.026 430.67L222.669 547.645Z"
        fill="url(#paint0_angular_1257_2468)"
      />
      <path
        d="M607.59 746.354L389.463 420.846L222.162 545.554L480.526 947.154L607.59 746.354Z"
        fill="#0D9488"
      />
      <defs>
        <radialGradient
          id="paint0_angular_1257_2468"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(448.265 389.925) rotate(-114.128) scale(222.545)"
        >
          <stop offset="0.709942" stopColor="#116A62" />
          <stop offset="0.720495" stopColor="#0D9488" />
        </radialGradient>
      </defs>
    </svg>
  )
}
