import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import React, { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteSignBoardApi, getSignBoardApi } from '../api/sign-board.api'
import Button from '../components/button/Button'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import { byteToSize } from '../helpers/common.helper'
import { signBoardTypeToText } from '../helpers/place.helper'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { SignBoardModel } from '../types/SignBoard'

function SignBoardView(): JSX.Element {
  const { id } = useParams()
  const [signBoard, setSignBoard] = useState<SignBoardModel>()
  const navigate = useNavigate()
  const handleOpenButton = (url: string) => {
    window.open(url)
  }
  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteSignBoardApi(id)
          navigate('/sign-board')
        },
      },
    })
  }
  useEffect(() => {
    if (id) {
      getSignBoardApi(id).then((data) => {
        setSignBoard(data)
      })
    }
  }, [id])

  return (
    <ViewLayout
      title="안내판 상세"
      description="안내판을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/sign-board" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/sign-board/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="업체">{signBoard?.place?.customer?.name}</SideLabel>
      <SideLabel label="관리코드">{signBoard?.code}</SideLabel>
      <SideLabel label="연결된 장소">{signBoard?.place?.title}</SideLabel>
      <SideLabel label="이름">{signBoard?.placeTitle}</SideLabel>
      <SideLabel label="점자 타이틀">{signBoard?.dotPlaceTitle}</SideLabel>
      <SideLabel label="종류">
        {signBoardTypeToText(signBoard?.signBoardType)}
      </SideLabel>
      <SideLabel label="아이콘">
        <div className="mb-4 group block w-40 overflow-hidden rounded-lg bg-slate-100">
          {signBoard?.icon && (
            <img
              src={signBoard?.icon?.path}
              alt={`${signBoard?.icon?.iconName} 아이콘`}
              className="w-full pointer-events-none object-cover group-hover:opacity-75"
            />
          )}
        </div>
      </SideLabel>
      <SideLabel label="설치 사진">
        <div className="mt-4">
          {signBoard?.files &&
            signBoard.files.map((file) => {
              return (
                <div>
                  <div className="flex flex-col gap-4">
                    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                      <li className="relative">
                        <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-slate-100 focus-within:ring-2 focus-within:ring-teal-500 focus-within:ring-offset-2 focus-within:ring-offset-slate-100">
                          <img
                            src={file?.path}
                            alt={file?.originalFilename}
                            className="pointer-events-none object-cover group-hover:opacity-75"
                          />
                          <button
                            type="button"
                            className="absolute inset-0 focus:outline-none"
                            onClick={() => {
                              if (file.path) handleOpenButton(file.path)
                            }}
                          >
                            <span className="sr-only">
                              이미지 크게 보기 {file.originalFilename}
                            </span>
                          </button>
                        </div>
                        <div className="mt-2">
                          {file.originalFilename && (
                            <p className="pointer-events-none block truncate text-sm font-medium text-slate-900">
                              {file.originalFilename}
                            </p>
                          )}
                          {file.size && (
                            <p className="pointer-events-none block text-sm font-medium text-slate-500">
                              {byteToSize(file.size)}
                            </p>
                          )}
                          {file.createdAt && (
                            <p className="pointer-events-none block text-sm font-medium text-slate-500">
                              {dayjs(file.createdAt).format(
                                'YYYY-MM-DD hh:mm:ss'
                              )}
                            </p>
                          )}
                          <span className="sr-only">파일 삭제</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            })}
        </div>
      </SideLabel>
    </ViewLayout>
  )
}
export default SignBoardView
