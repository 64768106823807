// eslint-disable-next-line import/no-cycle
import { api } from '../lib/api'
import { LoginRequest, LoginResponse } from '../types/api/login'

export const loginApi = async (
  loginDto: LoginRequest
): Promise<LoginResponse> => {
  return api.post(`/auth/login`, {
    email: loginDto.email,
    password: loginDto.password,
  })
}

export default { loginApi }
