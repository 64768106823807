import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/auth.hook'

export default function PrivateRouter(): JSX.Element {
  const { loginUser } = useAuth()

  if (!loginUser) {
    return <Navigate to="/login" replace />
  }

  return <Outlet />
}
