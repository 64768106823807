import React from 'react'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { classNames } from '../../helpers/common.helper'

type ErrorMessageProps = {
  error?: FieldValues
  name: string
  className?: string
}

function ErrorMessage({ error, name, className }: ErrorMessageProps) {
  const render = () => {
    if (!error) return null

    return (
      <p
        className={classNames(`mt-2 text-sm text-red-600`, className)}
        id={`${name}-error`}
        aria-live="assertive"
      >
        {error.message}
      </p>
    )
  }

  return render()
}

export default ErrorMessage
