import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCustomersApi } from '../api/customer.api'
import SearchInput from '../components/inputs/SearchInput'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import Select from '../components/select/Select'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TbodyStyle,
  TdEndStyle,
  TdStartStyle,
  TdStyle,
  TheadStyle,
  ThEndStyle,
  ThStartStyle,
  ThStyle,
} from '../styles/table.style'
import { CustomerType } from '../types/Customer'

export default function CustomerList(): JSX.Element {
  const [customers, setCustomers] = useState<CustomerType[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const getAdminUsers = useCallback(
    async (searchField?: string, keyword?: string) => {
      getCustomersApi({ page, limit, searchField, keyword }).then((data) => {
        setCustomers(data.items)
        setTotal(data.total)
      })
    },
    [page, limit]
  )
  const handleOnChangePage = (p: number) => setPage(p)
  const handleOnSearch = (searchField?: string, keyword?: string) => {
    getAdminUsers('nameOrCode', keyword)
  }

  useEffect(() => {
    document.title = `업체 관리 - ${ADMIN_TITLE}`
    getAdminUsers()
  }, [getAdminUsers])

  return (
    <ManageListLayout
      title="업체 관리"
      description="업체를 관리하는 페이지 입니다."
      createButtonChildren={
        <Link to="/customers/create" className={PrimaryButtonStyle}>
          업체 생성하기
        </Link>
      }
      searchChildren={
        <SearchInput className="w-30" onSearch={handleOnSearch} />
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col justify-between items-end sm:flex-col md:flex-row gap-2 pb-2">
          <Select
            label="페이지당 건수"
            options={['5', '10', '20', '50', '100'].map((item) => ({
              text: item,
              value: item,
            }))}
            defaultValue={20}
            onChange={(e) => {
              setLimit(Number(e.target.value))
              setPage(1)
            }}
          />
          <div className="text-slate-700">전체 수: {total}</div>
        </div>
      </div>

      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    이름
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    코드
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {customers.map((customer, i: number) => (
                  <tr key={customer.id}>
                    <td className={classNames(TdStartStyle)}>
                      {(page - 1) * limit + i + 1}
                    </td>
                    <td className={classNames(TdStyle)}>{customer.name}</td>
                    <td className={classNames(TdStyle)}>{customer.id}</td>
                    <td
                      className={classNames(
                        TdEndStyle,
                        'flex justify-end space-x-4'
                      )}
                    >
                      <Link
                        to={`/customers/view/${customer.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        상세<span className="sr-only">, {customer.name}</span>
                      </Link>
                      <Link
                        to={`/customers/edit/${customer.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        수정<span className="sr-only">, {customer.name}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}
