import Joi from 'joi'
import validator from '../lib/validate'

export const CustomerCreateValidation = validator().object({
  id: Joi.string()
    .regex(/^[\w|\d]{4}$/)
    .required()
    .messages({ 'string.pattern.base': '영문, 숫자로 4자리여야 합니다.' }),
  name: Joi.string().required().max(50),
})

export default {}
