import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  CreateAdminUserRequest,
  UpdateAdminUserRequest,
} from '../types/api/admin-user'
import { AdminUser } from '../types/User'

export const createAdminUserApi = async (
  data: CreateAdminUserRequest
): Promise<void> => {
  return api.post(`/admin-users`, data)
}

export const updateAdminUserApi = async (
  id: string,
  data: UpdateAdminUserRequest
): Promise<AdminUser> => {
  return api.patch(`/admin-users/${id}`, data)
}

export const getAdminUsersApi = async ({
  page,
  limit,
  searchField,
  keyword,
}: ListRequestDto): Promise<ListDto<AdminUser>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  return api.get(`/admin-users?page=${page}&limit=${limit}${searchQuery}`)
}

export const getAdminUserApi = async (id: string): Promise<AdminUser> => {
  return api.get(`/admin-users/${id}`)
}

export const deleteAdminUserApi = async (id: string): Promise<void> => {
  return api.delete(`/admin-users/${id}`)
}

export default {}
