import React, { SelectHTMLAttributes, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { classNames } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

export interface OptionProps {
  text: string
  value: string
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  id: string
  name: string
  label: string
  options?: OptionProps[]
  loadData?: () => Promise<OptionProps[]>
}
export default function SelectSideLabelForm(props: SelectProps): JSX.Element {
  const {
    name,
    id,
    label,
    options,
    onChange,
    onLoad,
    disabled,
    defaultValue,
    loadData,
    className,
    required,
  } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const [items, setItems] = useState<OptionProps[]>([])

  useEffect(() => {
    if (options) {
      setItems(options)
    }
    if (loadData) {
      loadData().then((data) => {
        setItems(data)
      })
    }

    const formValue = formMethods.getValues()[name]
    if (!formValue) {
      if (options && options.length > 0) {
        formMethods.setValue(name, options[0].value)
      }
    }
  }, [options, loadData, formMethods, name])

  return (
    <div
      className={classNames(
        'sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5',
        className
      )}
    >
      <label
        htmlFor={id}
        className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <select
          id={id}
          autoComplete={`${name}-name`}
          className="block w-full max-w-lg rounded-md border-slate-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          {...formMethods.register(name)}
          onChange={onChange}
          onLoad={onLoad}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {items?.map((op) => (
            <option value={op.value} key={`${id}-${op.value}`}>
              {op.text}
            </option>
          ))}
        </select>
      </div>
      <ErrorMessage error={error} name={name} />
    </div>
  )
}
