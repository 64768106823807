import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { joiResolver } from '@hookform/resolvers/joi'
import { ADMIN_TITLE } from '../constants/title'
import {
  updatePlaceApi,
  createPlaceApi,
  getPlaceApi,
  deletePlaceApi,
} from '../api/place.api'
import { UpdatePlaceRequest, CreatePlaceRequest } from '../types/api/place'
import Button from '../components/button/Button'
import {
  FacilitiesCategories,
  PlaceCategories,
  PlaceType,
} from '../enums/place.enum'
import { Place } from '../types/Place'
import { showAlert } from '../lib/alert'
import { usePlaceRefresh } from '../recoil/place.recoil'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { customerAtom } from '../recoil/customer.recoil'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import SelectSideLabelForm from '../components/forms/SelectSideLabel'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import TextAreaSideLabelForm from '../components/forms/TextareaSideLabel'
import useAuth from '../hooks/auth.hook'
import { loadPlaceNextType } from '../helpers/place.helper'
import ImageSelector from '../components/select/ImageSelector2'
import SideLabel from '../components/forms/SideLabel'
import { PlaceCreateValidation } from '../validations/place.validation'
import { getPlaceIconListApi } from '../api/place-icon.api'
import PlaceTypeSelector from '../components/place/PlaceTypeSelector'

function PlaceCreate(): JSX.Element {
  const methods = useForm({
    resolver: joiResolver(PlaceCreateValidation),
  })
  const navigate = useNavigate()
  const [types, setTypes] = useState<{ text: string; value: string }[]>([])
  const { id, parentId } = useParams()
  const customer = useRecoilValue(customerAtom)
  const [place, setPlace] = useState<Place | null>(null)
  const { isAdmin } = useAuth()
  const placeRefresh = usePlaceRefresh()

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updatePlaceApi(id, {
        ...formData,
        type: formData.placeType,
        category: formData.placeCategory,
        iconId: formData.iconId || undefined,
        available: formData.available,
      } as UpdatePlaceRequest)
      placeRefresh()
      navigate(`/place/children/${id}`)
    } else if (parentId) {
      const data = await createPlaceApi({
        ...formData,
        parentId,
        type: formData.placeType,
        category: formData.placeCategory,
        iconId: formData.iconId || undefined,
        available: formData.available,
      } as CreatePlaceRequest)
      placeRefresh()
      navigate(`/place/children/${data.id}`)
    } else {
      const data = await createPlaceApi({
        ...formData,
        type: formData.placeType,
        category: formData.placeCategory,
        iconId: formData.iconId || undefined,
        available: formData.available,
      } as CreatePlaceRequest)
      placeRefresh()
      navigate(`/place/children/${data.id}`)
    }
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deletePlaceApi(id)
          navigate('/place')
        },
      },
    })
  }

  useEffect(() => {
    document.title = `장소 수정 - ${ADMIN_TITLE}`
    if (id) {
      // 수정
      getPlaceApi(id).then((data) => {
        setPlace(data)
        setTypes(loadPlaceNextType(data.parent?.type))
        methods.setValue('placeType', data.type)
        methods.setValue('placeCategory', data.category)
        methods.setValue('title', data.title)
        methods.setValue('summary', data.summary)
        methods.setValue('notice', data.notice)
        methods.setValue('hour', data.hour)
        methods.setValue('contactTel', data.contactTel)
        methods.setValue('contactName', data.contactName)
        methods.setValue('customerId', data.customer?.id)
        methods.setValue('available', `${data.available}`)
        methods.setValue('latitude', data.latitude ? `${data.latitude}` : '')
        methods.setValue('longitude', data.longitude ? `${data.longitude}` : '')
        switch (data.type) {
          case PlaceType.Building:
            methods.setValue('buildingId', data.building?.id)
            break
          case PlaceType.Floor:
            methods.setValue('buildingId', data.building?.id)
            break
          default:
            methods.setValue('buildingId', data.building?.id)
            methods.setValue('floorId', data.floor?.id)
            break
        }
      })
    } else if (parentId) {
      // 부모가 있는 생성
      getPlaceApi(parentId).then((data) => {
        setTypes(loadPlaceNextType(data.type))
        methods.setValue('placeType', loadPlaceNextType(data.type)[0].value)
        methods.setValue('customerId', data.customer?.id)
        switch (data.type) {
          case PlaceType.Building:
            methods.setValue('buildingId', data.id)
            break
          case PlaceType.Floor:
            methods.setValue('buildingId', data.building?.id)
            methods.setValue('floorId', data.id)
            break
          default:
            methods.setValue('buildingId', data.building?.id)
            methods.setValue('floorId', data.floor?.id)
            break
        }
        methods.setValue('placeCategory', PlaceCategories[0].value)
      })
    } else {
      // 루트 생성
      setTypes(loadPlaceNextType())
      methods.setValue('placeType', 'building')
      methods.setValue('placeCategory', PlaceCategories[0].value)
    }
    if (!isAdmin && !parentId) {
      methods.setValue('customerId', customer)
    }
  }, [id, methods, parentId, isAdmin, customer])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title="장소 수정"
      description="장소를 관리하는 페이지 입니다."
      cancelButton={
        <Button onClick={() => navigate(-1)} className={DefaultButtonStyle}>
          취소
        </Button>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      {id && <SideLabel label="ID">{id}</SideLabel>}
      <SelectSideLabelForm
        name="available"
        id="available"
        label="사용여부"
        options={[
          { text: '사용', value: 'true' },
          { text: '미사용', value: 'false' },
        ]}
        required
      />
      <CustomerSelectorSideLabelForm
        required
        disabled={!!(parentId || place?.parent)}
      />

      <PlaceTypeSelector
        placeTypes={types}
        defaultPlaceType={place?.type}
        onCategoryChange={(value) => {
          methods.setValue(
            'title',
            FacilitiesCategories.find((item) => item.value === value)?.text
          )
        }}
      />
      <InputSideLabelForm
        id="title"
        name="title"
        type="text"
        label="제목"
        required
      />
      <SideLabel label="아이콘 선택">
        <ImageSelector
          name="iconId"
          defaultItem={
            place?.icon && {
              ...place.icon,
              label: place.icon.iconName,
            }
          }
          onSelectCallback={(iconId: string) => {
            methods.setValue('iconId', iconId)
          }}
          onSearchCallback={async (keyword: string) => {
            const placeIcons = await getPlaceIconListApi({
              page: 1,
              limit: 100,
              keyword,
            })
            return placeIcons.items.map((item) => ({
              ...item,
              label: item.iconName,
            }))
          }}
        />
      </SideLabel>
      <TextAreaSideLabelForm id="summary" name="summary" label="요약" />
      <TextAreaSideLabelForm id="notice" name="notice" label="공지사항" />
      <TextAreaSideLabelForm
        id="hour"
        name="hour"
        label="운영시간"
        defaultValue={`월 : 09:00 ~ 18:00
화 : 09:00 ~ 18:00
수 : 09:00 ~ 18:00
목 : 09:00 ~ 18:00
금 : 09:00 ~ 16:00
토 : 휴무
일 : 휴무
공휴일 : 휴무`}
      />
      <InputSideLabelForm
        id="contactTel"
        name="contactTel"
        type="text"
        label="전화번호"
        placeholder="02-1234-2345"
      />
      <InputSideLabelForm
        id="contactName"
        name="contactName"
        type="text"
        label="담당자명"
        placeholder="홍길동"
      />
      <InputSideLabelForm
        id="latitude"
        name="latitude"
        type="text"
        label="위도"
        placeholder="37.5504321"
      />
      <InputSideLabelForm
        id="longitude"
        name="longitude"
        type="text"
        label="경도"
        placeholder="127.0517829"
      />
    </ManageCreateLayout>
  )
}

export default PlaceCreate
