import React, { ButtonHTMLAttributes } from 'react'
import { classNames } from '../../helpers/common.helper'
import { PrimaryButtonStyle } from '../../styles/button.style'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  submit?: boolean
}

export default function Button({
  submit,
  children,
  className,
  onClick,
  disabled,
}: ButtonProps) {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classNames(className)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
