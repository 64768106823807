/* eslint-disable import/no-cycle */
import { CustomerType } from './Customer'
import { Qr } from './Qr'
import { AdminUser } from './User'

export enum DocumentType {
  Directory = 0,
  Document = 10,
  Page = 20,
}

export enum DocumentState {
  Temp = 0,
  UnUse = 10,
  Use = 20,
}

export interface DocumentModel {
  id: string
  type: DocumentType
  state: DocumentState
  title: string
  contents: string
  writer: AdminUser
  createdAt: Date
  customer: CustomerType
  qr?: Qr[]
}
