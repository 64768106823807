import { atom, selector } from 'recoil'
import { Place } from '../types/Place'
import { SurveyVersion } from '../types/Survey'
import { customerAtom } from './customer.recoil'

// base place
// selected base place by customer id
export const selectedBasePlaceListAtom = atom<{
  [customerId: string]: Place | undefined
}>({
  key: 'selectedBasePlaceListAtom',
  default: {},
})

// selected base place
export const basePlaceSelector = selector<Place | undefined>({
  key: 'basePlaceSelector',
  get: ({ get }) => {
    const customer = get(customerAtom)
    if (!customer) return undefined
    const selectedBasePlaceList = get(selectedBasePlaceListAtom)
    return selectedBasePlaceList[customer.id]
  },
  set: ({ get, set }, newValue) => {
    const customer = get(customerAtom)
    if (!customer) return
    const selectedBasePlaceList = get(selectedBasePlaceListAtom)
    set(selectedBasePlaceListAtom, {
      ...selectedBasePlaceList,
      [customer.id]: newValue as Place,
    })
  },
})

// version
// selected version by customer id
export const selectedVersionListAtom = atom<{
  [customerId: string]: SurveyVersion | undefined
}>({
  key: 'selectedVersionListAtom',
  default: {},
})
// selected version
export const versionSelector = selector<SurveyVersion | undefined>({
  key: 'versionSelector',
  get: ({ get }) => {
    const customer = get(customerAtom)
    if (!customer) return undefined
    const selectedVersionList = get(selectedVersionListAtom)
    return selectedVersionList[customer.id]
  },
  set: ({ get, set }, newValue) => {
    const customer = get(customerAtom)
    if (!customer) return
    const selectedVersionList = get(selectedVersionListAtom)
    set(selectedVersionListAtom, {
      ...selectedVersionList,
      [customer.id]: newValue as SurveyVersion,
    })
  },
})

export default {}
