import { useEffect, useState } from 'react'
import React, { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteAdminUserApi } from '../api/admin-user.api'
import { getSurveyApi } from '../api/survey.api'
import Button from '../components/button/Button'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import {
  availableToText,
  placeCategoryToText,
  placeTypeToText,
  signBoardTypeToText,
} from '../helpers/place.helper'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { Survey } from '../types/Survey'

function SurveyView(): JSX.Element {
  const { id } = useParams()
  const [survey, setSurvey] = useState<Survey>()
  const navigate = useNavigate()
  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteAdminUserApi(id)
          navigate('/admin-users')
        },
      },
    })
  }
  useEffect(() => {
    if (id) {
      getSurveyApi(id).then((data) => setSurvey(data))
    }
  }, [id])

  return (
    <ViewLayout
      title="현장조사 데이터 상세"
      description="현장조사 데이터를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/survey" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/survey/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="업체 선택">{survey?.customer?.name}</SideLabel>
      <SideLabel label="버전 선택">{survey?.version?.name}</SideLabel>
      <SideLabel label="기본 장소/건물 선택">
        {survey?.building?.title}
      </SideLabel>
      <SideLabel label="장소 구분">
        {placeTypeToText(survey?.placeType)}
      </SideLabel>
      <SideLabel label="장소 세부 구분">
        {placeCategoryToText(survey?.placeCategory)}
      </SideLabel>

      <SideLabel label="층 수">{survey?.floor?.title}</SideLabel>
      <SideLabel label="장소명">{survey?.placeTitle}</SideLabel>
      <SideLabel label="장소설명">{survey?.summary}</SideLabel>
      <SideLabel label="안내판 유형">
        {signBoardTypeToText(survey?.signBoardType)}
      </SideLabel>
      <SideLabel label="사용여부">
        {availableToText(survey?.available)}
      </SideLabel>
    </ViewLayout>
  )
}
export default SurveyView
