import React from 'react'
import TableExt from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import { EditorFunctionProps } from '../types/editor.type'

export function useTable() {
  return [
    TableExt.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
  ]
}

export default function Table({ editor }: EditorFunctionProps) {
  if (!editor) return null
  return (
    <div>
      <button
        type="button"
        onClick={() =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
      >
        insertTable
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().addColumnBefore().run()}
      >
        addColumnBefore
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().addColumnAfter().run()}
      >
        addColumnAfter
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().deleteColumn().run()}
      >
        deleteColumn
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().addRowBefore().run()}
      >
        addRowBefore
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().addRowAfter().run()}
      >
        addRowAfter
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().deleteRow().run()}
      >
        deleteRow
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().deleteTable().run()}
      >
        deleteTable
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().mergeCells().run()}
      >
        mergeCells
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().splitCell().run()}
      >
        splitCell
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
      >
        toggleHeaderColumn
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeaderRow().run()}
      >
        toggleHeaderRow
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeaderCell().run()}
      >
        toggleHeaderCell
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().mergeOrSplit().run()}
      >
        mergeOrSplit
      </button>
      <button
        type="button"
        onClick={() =>
          editor.chain().focus().setCellAttribute('colspan', 2).run()
        }
      >
        setCellAttribute
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().fixTables().run()}
      >
        fixTables
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().goToNextCell().run()}
      >
        goToNextCell
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().goToPreviousCell().run()}
      >
        goToPreviousCell
      </button>
    </div>
  )
}
