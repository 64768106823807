import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { isAdminSelector, loginUserSelector } from '../recoil/login-user.recoil'
import { LoginRequest } from '../types/api/login'
import { AdminUser } from '../types/User'
import { logout as removeLoginUser, login as signIn } from '../lib/auth'

export default function useAuth() {
  const loginUser = useRecoilValue(loginUserSelector)
  const isAdmin = useRecoilValue(isAdminSelector)
  const refreshLoginUser = useRecoilRefresher_UNSTABLE(loginUserSelector)

  const login = async (req: LoginRequest): Promise<AdminUser | null> => {
    const user = await signIn(req)
    refreshLoginUser()
    return user
  }

  const logout = () => {
    removeLoginUser()
    refreshLoginUser()
  }

  return {
    loginUser,
    isAdmin,
    login,
    logout,
  }
}
