import { Transition, Dialog } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { alertAtom } from '../../recoil/notification.recoil'
import AlertErrorIcon from './AlertErrorIcon'

export default function Alert() {
  const state = useRecoilValue(alertAtom)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!state)
  }, [state])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {!state?.icon && <AlertErrorIcon />}
                  {state?.icon && state.icon}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-slate-900 whitespace-pre-line"
                    >
                      {state?.title}
                    </Dialog.Title>
                    {state?.desc && (
                      <div className="mt-2">
                        <p className="text-sm text-slate-500 whitespace-pre-line">
                          {state.desc}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-2 mt-5 sm:mt-6">
                  {!state?.confirm && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => {
                        if (state?.onClick) state?.onClick()
                        setOpen(false)
                      }}
                    >
                      확인
                    </button>
                  )}
                  {state?.confirm && (
                    <>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-slate-500 shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2 sm:text-sm"
                        onClick={() => {
                          if (state.confirm?.cancelClick)
                            state.confirm?.cancelClick()
                          setOpen(false)
                        }}
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => {
                          state.confirm?.confirmClick()
                          setOpen(false)
                        }}
                      >
                        확인
                      </button>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
