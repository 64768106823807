import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import reportWebVitals from './reportWebVitals'
import Router from './router/Router'
import './index.css'
import Alert from './components/notifications/Alert'
import { RecoilExternalState } from './lib/recoilExternalState'
import Toast from './components/notifications/Toast'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <React.Suspense>
      <DndProvider backend={HTML5Backend}>
        <Router />
        <Alert />
        <Toast />
        <RecoilExternalState />
      </DndProvider>
    </React.Suspense>
  </RecoilRoot>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
