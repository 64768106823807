import Joi, { CustomHelpers } from 'joi'
import validator from '../lib/validate'

export default {}

export const SurveyCreateValidation = validator().object({
  customerId: Joi.string().required(),
  basePlaceId: Joi.string().required(),
  surveyVersionId: Joi.number().required(),
  placeTitle: Joi.string().required(),
  placeType: Joi.string().required(),
  placeCategory: Joi.string().optional(),
  summary: Joi.optional(),
  floor: Joi.string().required(),
  signBoardType: Joi.optional(),
})

export const SurveyUpdateValidation = validator().object({
  customerId: Joi.string().required(),
  basePlaceId: Joi.string().required(),
  surveyVersionId: Joi.number().required(),
  placeTitle: Joi.string().required(),
  placeType: Joi.string().required(),
  placeCategory: Joi.string().optional(),
  summary: Joi.optional(),
  floor: Joi.string().required(),
  signBoardType: Joi.optional(),
  available: Joi.boolean().required(),
})

export const SurveyVersionCreateValidation = validator().object({
  customerId: Joi.string().required(),
  name: Joi.string().required(),
  description: Joi.optional(),
})

export const SurveyImportDataValidation = validator().object({
  customerId: Joi.string().required(),
  basePlaceId: Joi.string().required(),
  file: Joi.custom((value: FileList, helpers: CustomHelpers) => {
    if (value.length === 0) {
      return helpers.message({ custom: '파일은 필수 입니다.' })
    }
    return value
  }),
})
