import { joiResolver } from '@hookform/resolvers/joi'
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  createSurveyApi,
  deleteSurveyApi,
  deleteSurveyVersionApi,
  getSurveyApi,
  updateSurveyApi,
} from '../api/survey.api'
import Button from '../components/button/Button'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import BasePlaceFinder from '../components/finders/BasePlaceFinder'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import SideLabel from '../components/forms/SideLabel'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import AlertWarningIcon from '../components/notifications/AlertWarningIcon'
import CreateData from '../components/survey/CreateData'
import SurveyVersionSelectorForm from '../components/survey/SurveyVersionSelectorForm'
import { ADMIN_TITLE } from '../constants/title'
import useSurvey from '../hooks/survey.hook'
import { showAlert } from '../lib/alert'
import { showToast } from '../lib/toast'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { CreateSurveyRequest, UpdateSurveyRequest } from '../types/api/survey'
import { Survey } from '../types/Survey'
import {
  SurveyCreateValidation,
  SurveyUpdateValidation,
} from '../validations/survey.validation'

function SurveyCreate2() {
  const navigate = useNavigate()
  const { id } = useParams()
  const methods = useForm({
    resolver: joiResolver(id ? SurveyUpdateValidation : SurveyCreateValidation),
  })
  const [title, setTitle] = useState('현장조사 데이터 생성')
  const {
    refreshVersions: refreshVersion,
    setVersion,
    setCustomer,
    setBasePlace,
  } = useSurvey()
  const [survey, setSurvey] = useState<Survey>()
  const hasPublished = survey && !!survey?.version?.publishedAt
  let saveType = 'save'

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updateSurveyApi(id, {
        ...(formData as UpdateSurveyRequest),
        buildingId: formData.basePlaceId,
      })
      navigate('/survey')
    } else {
      await createSurveyApi({
        ...(formData as CreateSurveyRequest),
        buildingId: formData.basePlaceId,
        available: true,
      })
      if (saveType === 'save') {
        navigate('/survey')
      } else {
        showToast(`${formData.placeTitle} 생성되었습니다.`)
        methods.setValue('placeTitle', undefined)
        methods.setValue('summary', undefined)
      }
    }
  }

  const handleAddClick = () => {
    saveType = 'saveAndAdd'
  }

  const handleDeleteButton = () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      icon: <AlertWarningIcon />,
      confirm: {
        confirmClick: async () => {
          if (id) await deleteSurveyApi(id)
          refreshVersion()
          navigate('/survey')
        },
      },
    })
  }

  useEffect(() => {
    document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('현장조사 데이터 수정')
      getSurveyApi(id).then((data) => {
        setCustomer(data.customer)
        setBasePlace(data.building)
        setVersion(data.version)
        setSurvey(data)
      })
    }
  }, [id, methods, title, setCustomer, setVersion, setBasePlace])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="현장조사 데이터를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/survey" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        !hasPublished && (
          <div className="flex flex-row space-x-1">
            {!id && (
              <Button
                submit
                className={PrimaryButtonStyle}
                onClick={() => handleAddClick()}
              >
                저장하고 추가생성
              </Button>
            )}
            <Button submit className={PrimaryButtonStyle}>
              저장
            </Button>
          </div>
        )
      }
      delButton={
        id &&
        !hasPublished && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      <SideLabel label="ID">{id}</SideLabel>
      <CustomerSelectorSideLabelForm disabled={hasPublished} required />
      <SurveyVersionSelectorForm disabled={hasPublished} required />
      <BasePlaceFinder disabled={hasPublished} required />
      <CreateData survey={survey} />
    </ManageCreateLayout>
  )
}

export default SurveyCreate2
