import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  FacilitiesCategories,
  PlaceTypes,
  SignBoardTypes,
} from '../../enums/place.enum'
import useSurvey from '../../hooks/survey.hook'
import { showAlert } from '../../lib/alert'
import { basePlaceSelector } from '../../recoil/survey.recoil'
import { Survey } from '../../types/Survey'
import InputSideLabelForm from '../forms/InputSideLabel'
import SelectSideLabelForm from '../forms/SelectSideLabel'
import TextAreaSideLabelForm from '../forms/TextareaSideLabel'
import { StepItemElementProps } from '../layout/StepLayout'
import PlaceTypeSelector from '../place/PlaceTypeSelector'

type CreateDataProps = StepItemElementProps & {
  survey?: Survey
}

function CreateData({ nextHref, prevHref, survey }: CreateDataProps) {
  const methods = useFormContext()
  const { basePlace } = useSurvey()
  const navigate = useNavigate()
  const hasPublished = survey && !!survey.version?.publishedAt

  const handleCategoryChange = (value: string) => {
    methods.setValue(
      'placeTitle',
      FacilitiesCategories.find((item) => item.value === value)?.text
    )
  }

  useEffect(() => {
    if (survey) {
      const floor = survey?.floor?.title.replace('층', '')
      methods.setValue('floor', floor)
      methods.setValue('placeTitle', survey?.placeTitle)
      methods.setValue('placeType', survey?.placeType)
      methods.setValue('placeCategory', survey.placeCategory)
      methods.setValue('summary', survey?.summary)
      methods.setValue('signBoardType', survey?.signBoardType)
      methods.setValue('available', `${survey?.available}`)
    }
  }, [survey, methods])
  return (
    <div className="space-y-6 sm:space-y-5">
      <PlaceTypeSelector
        placeTypes={PlaceTypes}
        defaultPlaceType={survey?.placeType}
        onCategoryChange={handleCategoryChange}
        disabled={survey && !!survey.version?.publishedAt}
      />
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-1 sm:col-span-3">
          <InputSideLabelForm
            id="floor"
            name="floor"
            type="number"
            label="층수"
            required
            disabled={hasPublished}
          >
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-slate-500 sm:text-sm">층</span>
            </div>
          </InputSideLabelForm>
        </div>
        <div className="col-span-2 sm:col-span-3">
          <InputSideLabelForm
            id="placeTitle"
            name="placeTitle"
            type="text"
            label="장소명"
            required
            disabled={hasPublished}
          />
        </div>
      </div>
      <TextAreaSideLabelForm
        id="summary"
        name="summary"
        label="장소 설명"
        disabled={hasPublished}
      />
      <SelectSideLabelForm
        id="signBoardType"
        name="signBoardType"
        label="안내판 유형"
        options={SignBoardTypes}
        disabled={hasPublished}
      />
      {survey && (
        <SelectSideLabelForm
          id="available"
          name="available"
          label="사용여부"
          options={[
            { text: '사용', value: 'true' },
            { text: '미사용', value: 'false' },
          ]}
          disabled={hasPublished}
        />
      )}
    </div>
  )
}

export default CreateData
