import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import { CreateQrRequest, UpdateQrRequest } from '../types/api/qr'
import { Qr } from '../types/Qr'

export const createQrApi = async (data: CreateQrRequest): Promise<void> => {
  return api.post(`/qr`, data)
}

export const updateQrApi = async (
  id: string,
  data: UpdateQrRequest
): Promise<void> => {
  return api.patch(`/qr/${id}`, data)
}

export const getQrListApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  customerId?: string
): Promise<ListDto<Qr>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(
    `/qr?page=${page}&limit=${limit}${customerQuery}${searchQuery}`
  )
}

export const getQrApi = async (id: string): Promise<Qr> => {
  return api.get(`/qr/${id}`)
}

export const deleteQrApi = async (id: string): Promise<void> => {
  return api.delete(`/qr/${id}`)
}

export default { getQrListApi }
