import { useRecoilState, useRecoilValue } from 'recoil'
import {
  customerAtom,
  usableCustomersSelector,
} from '../recoil/customer.recoil'
import { basePlaceListSelector } from '../recoil/place.recoil'
import {
  useSurveyVersionsRefresh,
  versionsSelector,
} from '../recoil/survey-version.recoil'
import { basePlaceSelector, versionSelector } from '../recoil/survey.recoil'

export default function useSurvey() {
  const [customer, setCustomer] = useRecoilState(customerAtom)
  const customers = useRecoilValue(usableCustomersSelector)
  const [basePlace, setBasePlace] = useRecoilState(basePlaceSelector)
  const basePlaceList = useRecoilValue(basePlaceListSelector)
  const [version, setVersion] = useRecoilState(versionSelector)
  const versions = useRecoilValue(versionsSelector)
  const refreshVersions = useSurveyVersionsRefresh()

  return {
    customer,
    setCustomer,
    customers,
    basePlace,
    setBasePlace,
    basePlaceList,
    version,
    setVersion,
    versions,
    refreshVersions,
  }
}
