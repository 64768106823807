import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  BulkCreateSurveyRequest,
  CreateSurveyRequest,
  CreateSurveyVersionRequest,
  PublishApiResponse,
  UpdateSurveyRequest,
  UpdateSurveyVersionRequest,
} from '../types/api/survey'
import { FloorModel } from '../types/Place'
import { Survey, SurveyAvailableCount, SurveyVersion } from '../types/Survey'

export const getSurveyListApi = async (
  {
    page,
    limit,
    searchField,
    keyword,
    sortField,
    sortDirection,
  }: ListRequestDto,
  customerId?: string,
  versionId?: number,
  basePlaceId?: string,
  floorId?: string
): Promise<ListDto<Survey>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  const versionQuery = versionId ? `&versionId=${versionId}` : ''
  const basePlaceQuery = basePlaceId ? `&basePlaceId=${basePlaceId}` : ''
  const sortQuery =
    sortField && sortDirection
      ? `&sortField=${sortField}&sortDirection=${sortDirection}`
      : ''
  const floorQuery = floorId ? `&floorId=${floorId}` : ''
  return api.get(
    `/survey?page=${page}&limit=${limit}${searchQuery}${customerQuery}${versionQuery}${basePlaceQuery}${sortQuery}${floorQuery}`
  )
}
export const getSurveyApi = async (surveyId: string): Promise<Survey> => {
  return api.get(`/survey/${surveyId}`)
}

export const createSurveyVersionApi = async (
  data: CreateSurveyVersionRequest
): Promise<SurveyVersion> => {
  return api.post(`/survey-version`, data)
}

export const updateSurveyVersionApi = async (
  id: string,
  data: UpdateSurveyVersionRequest
): Promise<SurveyVersion> => {
  return api.patch(`/survey-version/${id}`, data)
}

export const getSurveyVersionListApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  customerId?: string
): Promise<ListDto<SurveyVersion>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(
    `/survey-version?page=${page}&limit=${limit}${searchQuery}${customerQuery}`
  )
}

export const getSurveyVersionApi = async (
  id: string
): Promise<SurveyVersion> => {
  return api.get(`/survey-version/${id}`)
}

export const getSurveyVersionByCustomerIdApi = async (
  customerId: string
): Promise<SurveyVersion> => {
  return api.get(`/survey-version/customer/${customerId}`)
}

export const deleteSurveyVersionApi = async (id: string): Promise<void> => {
  return api.delete(`/survey-version/${id}`)
}

export const createSurveyApi = async (
  data: CreateSurveyRequest
): Promise<Survey> => {
  return api.post(`/survey`, data)
}

export const bulkCreateSurveyApi = async (
  data: BulkCreateSurveyRequest
): Promise<SurveyVersion> => {
  const formData = new FormData()
  formData.append('customerId', data.customerId)
  formData.append('buildingId', data.buildingId)
  data.file.forEach((f: File) => {
    formData.append('file', f)
  })
  return api.post(`/survey/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const updateSurveyApi = async (
  id: string,
  formData: UpdateSurveyRequest
): Promise<void> => {
  return api.patch(`/survey/${id}`, formData)
}

export const publishApi = async (
  versionId: number
): Promise<PublishApiResponse> => {
  return api.patch(`/survey/chkava/${versionId}`)
}

export const toggleAvailableApi = async (surveyId: number) => {
  return api.patch(`/survey/${surveyId}/toggle`)
}

export const deleteSurveyApi = async (id: string): Promise<void> => {
  return api.delete(`/survey/${id}`)
}

export const getFloorsApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  surveyVersionId: number
): Promise<ListDto<FloorModel>> => {
  return api.get(
    `/survey/floors/?page=${page}&limit=${limit}&surveyVersionId=${surveyVersionId}`
  )
}

export const getPublishesApi = async (
  versionId: number
): Promise<SurveyAvailableCount> => {
  return api.get(`/survey/${versionId}/publishes`)
}

export default {}
