import {
  FacilitiesCategories,
  PlaceCategories,
  PlaceCategory,
  PlaceType,
  SignBoardType,
} from '../enums/place.enum'
import { Place } from '../types/Place'

export default {}

export const placeTypeToText = (type?: PlaceType): string => {
  switch (type) {
    case PlaceType.Building:
      return '건물'
    case PlaceType.Floor:
      return '층'
    case PlaceType.Place:
      return '공간'
    case PlaceType.Object:
      return '사물'
    case PlaceType.Facilities:
      return '편의시설'
    default:
      return '정의되지 않음'
  }
}

export const placeCategoryToText = (category?: PlaceCategory): string => {
  if (!category) return '미지정'
  const placeCategory = PlaceCategories.find((item) => item.value === category)
  if (placeCategory) return placeCategory.text
  const faCategory = FacilitiesCategories.find(
    (item) => item.value === category
  )
  if (faCategory) return faCategory.text
  return `정의되지 않음(${category})`
}

export const signBoardTypeToText = (type?: SignBoardType): string => {
  switch (type) {
    case SignBoardType.Large:
      return '대'
    case SignBoardType.Medium:
      return '중'
    case SignBoardType.Small:
      return '소'
    default:
      return '미지정'
  }
}

export const loadPlaceNextType = (type?: string) => {
  switch (type) {
    case PlaceType.Building:
      return [
        { text: placeTypeToText(PlaceType.Floor), value: PlaceType.Floor },
        {
          text: placeTypeToText(PlaceType.Building),
          value: PlaceType.Building,
        },
      ]
    case PlaceType.Floor:
      return [
        { text: placeTypeToText(PlaceType.Place), value: PlaceType.Place },
        {
          text: placeTypeToText(PlaceType.Facilities),
          value: PlaceType.Facilities,
        },
        { text: placeTypeToText(PlaceType.Object), value: PlaceType.Object },
      ]
    default:
      return [
        {
          text: placeTypeToText(PlaceType.Building),
          value: PlaceType.Building,
        },
        { text: placeTypeToText(PlaceType.Place), value: PlaceType.Place },
        { text: placeTypeToText(PlaceType.Object), value: PlaceType.Object },
      ]
  }
}

export const sortFloor = (aTitle: string, bTitle: string) => {
  const a = Number(aTitle.replace('층', '')) || aTitle
  const b = Number(bTitle.replace('층', '')) || bTitle
  return a > b ? 1 : -1
}

export const childrenSort = (a: Place, b: Place) => {
  return sortFloor(a.title, b.title)
}

export const availableToText = (available?: boolean): string => {
  return available ? '사용' : '미사용'
}
