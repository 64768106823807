import React, { InputHTMLAttributes } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../error-message/ErrorMessage'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  name: string
  label: string
}

export default function Input(props: InputProps): JSX.Element {
  const { name, id, label, type, placeholder, readOnly } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const hasError = !!error

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-slate-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={type}
          className="block w-full pr-10 border-slate-300 text-slate-900 placeholder-slate-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm rounded-md"
          placeholder={placeholder}
          required={false}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          readOnly={readOnly}
          {...formMethods.register(name)}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <ErrorMessage error={error} name={name} />
    </div>
  )
}
