export interface Role {
  id: string
  name: string
}

export enum RoleType {
  ADMIN = 'admin',
  MANAGER = 'manager',
  MEMBER = 'member',
}
