import React, { FormHTMLAttributes } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  methods: UseFormReturn
  onSubmit: SubmitHandler<FieldValues>
}
function Form(props: FormProps) {
  const { methods, onSubmit, className, children } = props
  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
