import React, { HTMLAttributes } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Level } from '@tiptap/extension-heading'
import { EditorFunctionProps } from '../types/editor.type'

type HeadingProps = EditorFunctionProps & {
  level: Level
}

export default function Heading({ editor, level, children }: HeadingProps) {
  if (!editor) return null
  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
      className={editor.isActive('heading', { level }) ? 'is-active' : ''}
    >
      {children}
    </button>
  )
}
