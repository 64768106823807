import React, { HTMLAttributes } from 'react'
import { classNames } from '../../helpers/common.helper'

type SideLabelProps = HTMLAttributes<HTMLElement> & {
  label: string
  required?: boolean
}

export default function SideLabel({
  children,
  className,
  label,
  required,
  id,
}: SideLabelProps) {
  return (
    <div
      className={classNames(
        'sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5',
        className
      )}
    >
      <label
        htmlFor={id}
        className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">{children}</div>
    </div>
  )
}
