import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { PlaceType, FacilitiesCategories } from '../../enums/place.enum'
import SelectSideLabelForm, { OptionProps } from '../forms/SelectSideLabel'
import SideLabel from '../forms/SideLabel'
import Select2Form from '../forms/Select2'

type PlaceTypeSelectorProps = HTMLAttributes<HTMLElement> & {
  placeTypes?: OptionProps[]
  defaultPlaceType?: PlaceType
  disabled?: boolean
  onCategoryChange?: (value: string) => void
}

function PlaceTypeSelector({
  placeTypes,
  defaultPlaceType,
  onCategoryChange,
  disabled,
}: PlaceTypeSelectorProps) {
  const methods = useFormContext()
  const [placeType, setPlaceType] = useState<PlaceType>(PlaceType.Place)

  useEffect(() => {
    if (defaultPlaceType) {
      setPlaceType(defaultPlaceType || PlaceType.Place)
      methods.setValue('placeType', defaultPlaceType || PlaceType.Place)
    }
  }, [defaultPlaceType, placeTypes, methods])
  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="col-span-1 sm:col-span-3">
        <SideLabel label="장소 구분" id="placeType" required>
          <Select2Form
            id="placeType"
            name="placeType"
            options={placeTypes}
            onChange={(e) => {
              setPlaceType(e.target.value as PlaceType)
              methods.setValue('placeType', e.target.value)
              if (e.target.value !== PlaceType.Facilities) {
                methods.setValue('placeCategory', undefined)
              }
            }}
            disabled={disabled}
          />
        </SideLabel>
      </div>
      <div className="col-span-2 sm:col-span-3">
        {placeType === PlaceType.Facilities && (
          <SideLabel label="장소 세부 구분" id="placeCategory" required>
            <Select2Form
              id="placeCategory"
              name="placeCategory"
              options={FacilitiesCategories}
              disabled={disabled}
              onChange={(e) => {
                if (onCategoryChange && placeType === PlaceType.Facilities) {
                  onCategoryChange(e.target.value)
                }
              }}
            />
          </SideLabel>
        )}
      </div>
    </div>
  )
}

export default PlaceTypeSelector
