import { joiResolver } from '@hookform/resolvers/joi'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  updateNfcApi,
  createNfcApi,
  deleteNfcApi,
  getNfcApi,
} from '../../api/nfc.api'
import { ADMIN_TITLE } from '../../constants/title'
import { getServiceId } from '../../helpers/common.helper'
import useAuth from '../../hooks/auth.hook'
import { showAlert } from '../../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../../styles/button.style'
import { UpdateNfcRequest, CreateNfcRequest } from '../../types/api/nfc'
import { NfcCreateValidation } from '../../validations/nfc.validation'
import Button from '../button/Button'
import CustomerSelectorSideLabelForm from '../customers/selectorWithLabelForm'
import { FinderItem } from '../finders/Finder'
import InputSideLabelForm from '../forms/InputSideLabel'
import ManageCreateLayout from '../layout/ManageCreateLayout'
import NfcContentForm from './NfcContentForm'

function AdminNfcCreate() {
  const methods = useForm({
    resolver: joiResolver(NfcCreateValidation),
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const [title, setTitle] = useState('NFC 생성')
  const [document, setDocument] = useState<FinderItem>()
  const [place, setPlace] = useState<FinderItem>()
  const { isAdmin } = useAuth()
  const [contentType, setContentType] = useState<string>()

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { code, name, customerId, type, documentId, placeId, contents } =
      formData
    const getContentId = (): string | undefined => {
      switch (type) {
        case 'document':
          return formData.documentId
        case 'place':
          return formData.placeId
        default:
          return undefined
      }
    }
    const data = {
      id: formData.id,
      code,
      name,
      customerId,
      serviceId: getServiceId(type),
      content: {
        type,
        contentId: getContentId(),
        contents,
        documentId: documentId || undefined,
        placeId: placeId || undefined,
      },
    }
    if (id) {
      await updateNfcApi(id, data as UpdateNfcRequest)
    } else {
      await createNfcApi(data as CreateNfcRequest)
    }
    navigate('/nfc')
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteNfcApi(id)
          navigate('/nfc')
        },
      },
    })
  }

  const loadData = useCallback(async () => {
    if (id) {
      await getNfcApi(id).then((data) => {
        methods.setValue('id', data.id)
        methods.setValue('name', data.name)
        methods.setValue('customerId', data.customer?.id)
        if (data.contents.length > 0) {
          const { type } = data.contents[0]
          methods.setValue('type', type)
          setContentType(type)
          switch (type) {
            case 'document':
              if (data.contents[0].document)
                setDocument({
                  text: data.contents[0].document?.title,
                  value: data.contents[0].document?.id,
                })
              break
            case 'place':
              if (data.contents[0].place)
                setPlace({
                  text: data.contents[0].place?.title,
                  value: data.contents[0].place?.id,
                })
              break
            case 'link':
            default:
              methods.setValue('contents', data.contents[0].contents)
              break
          }
        }
      })
    }
  }, [id, methods])

  useEffect(() => {
    window.document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('NFC 수정')
    }
    loadData()
  }, [loadData, id, title])

  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="NFC를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/nfc" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      <InputSideLabelForm
        id="id"
        name="id"
        type="text"
        label="ID"
        required
        disabled={!!id}
      />

      {isAdmin && <CustomerSelectorSideLabelForm />}
      <NfcContentForm
        document={document}
        place={place}
        contentType={contentType}
      />
    </ManageCreateLayout>
  )
}

export default AdminNfcCreate
