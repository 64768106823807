import { CheckCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'

function AlertCheckIcon() {
  return (
    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
      <CheckCircleIcon className="h-12 w-12 text-teal-600" aria-hidden="true" />
    </div>
  )
}

export default AlertCheckIcon
