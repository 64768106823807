import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function NoneLayout() {
  useEffect(() => {
    const html = document.querySelector('html') as HTMLElement
    const body = document.querySelector('body') as HTMLElement
    const root = document.querySelector('#root') as HTMLElement

    html.classList.add('h-full')
    body.classList.add('h-full')
    root.classList.add('h-full')
  })

  return <Outlet />
}
