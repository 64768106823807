import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getCustomersApi } from '../api/customer.api'
import { bulkCreateNfcApi } from '../api/nfc.api'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import SelectForm from '../components/forms/Select'
import { ADMIN_TITLE } from '../constants/title'
import { BulkCreateNfcRequest } from '../types/api/nfc'
import { CustomerType } from '../types/Customer'

const services = [
  { value: 'reader', text: '리더' },
  { value: 'place', text: '플레이스' },
]
function NfcBulkCreate(): JSX.Element {
  const methods = useForm()
  const navigate = useNavigate()
  const [customers, setCustomers] = useState<{ text: string; value: string }[]>(
    []
  )

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const ids = formData.ids.replace(/\s/g, '').split(',')
    await bulkCreateNfcApi({
      ...formData,
      ids,
    } as BulkCreateNfcRequest)
    navigate('/nfc')
  }

  useEffect(() => {
    document.title = `${ADMIN_TITLE} - NFC`
    getCustomersApi({ page: 1, limit: 100 }).then((data) => {
      setCustomers(
        data.items.map((item: CustomerType) => {
          return { text: item.name, value: item.id }
        })
      )
    })
  }, [])
  return (
    <Form
      methods={methods}
      onSubmit={handleSubmit}
      className="py-6 px-4 space-y-8 divide-y divide-slate-200 bg-slate-100"
    >
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-slate-900">
                NFC
              </h3>
              <p className="mt-1 text-sm text-slate-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid gap-6">
                  <Input id="ids" name="ids" type="text" label="Ids" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <SelectForm
                    id="serviceId"
                    name="serviceId"
                    label="Service"
                    options={services}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <SelectForm
                    id="customerId"
                    name="customerId"
                    label="Customer"
                    options={customers}
                  />
                </div>
              </div>
              <div className="px-4 py-3 bg-slate-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default NfcBulkCreate
