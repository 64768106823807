import Joi from 'joi'
import validator from '../lib/validate'

export default {}

export const SignboardCreateValidation = validator().object({
  customerId: Joi.string().required(),
  code: Joi.optional(),
  placeId: Joi.string().required(),
  placeTitle: Joi.string().required(),
  signBoardType: Joi.string().required(),
  dotPlaceTitle: Joi.string().required(),
  file: Joi.optional(),
  iconId: Joi.optional(),
})
