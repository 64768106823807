import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  CreatePlaceIconRequest,
  UpdatePlaceIconRequest,
} from '../types/api/place-icon'
import PlaceIcon from '../types/PlaceIcon'

export default {}
export const createPlaceIconApi = async (
  data: CreatePlaceIconRequest
): Promise<PlaceIcon> => {
  const formData = new FormData()
  formData.append('iconName', data.iconName)
  formData.append('category', data.category)
  if (data.file.length > 0) {
    data.file.forEach((item) => {
      formData.append('file', item)
    })
  }

  return api.post(`/place-icons`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const updatePlaceIconApi = async (
  id: string,
  data: UpdatePlaceIconRequest
): Promise<PlaceIcon> => {
  const formData = new FormData()
  formData.append('iconName', data.iconName)
  formData.append('category', data.category)
  if (data.file.length > 0) {
    data.file.forEach((item) => {
      formData.append('file', item)
    })
  }

  return api.patch(`/place-icons/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getPlaceIconListApi = async ({
  page,
  limit,
  keyword,
  searchField = 'iconName',
}: ListRequestDto): Promise<ListDto<PlaceIcon>> => {
  const searchQuery = keyword
    ? `&searchField=${searchField}&keyword=${keyword}`
    : ''
  return api.get(`/place-icons?page=${page}&limit=${limit}${searchQuery}`)
}

export const getPlaceIconApi = async (id: string): Promise<PlaceIcon> => {
  return api.get(`/place-icons/${id}`)
}

export const deletePlaceIconApi = async (id: string) => {
  return api.delete(`/place-icons/${id}`)
}
