import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getDocumentsApi } from '../../api/document.api'
import { searchPlaceListApi } from '../../api/place.api'
import { customerAtom } from '../../recoil/customer.recoil'
import Finder, { FinderItem } from '../finders/Finder'
import InputSideLabelForm from '../forms/InputSideLabel'
import SelectSideLabelForm from '../forms/SelectSideLabel'

const types = [
  { value: 'document', text: '문서' },
  { value: 'place', text: '플레이스' },
  { value: 'link', text: '링크' },
]

type NfcContentFormProps = HTMLAttributes<HTMLElement> & {
  document?: FinderItem
  place?: FinderItem
  contentType?: string
}

export default function NfcContentForm({
  document,
  place,
  contentType,
}: NfcContentFormProps) {
  const [currentContentType, setCurrentContentType] =
    useState<string>('document')
  const customer = useRecoilValue(customerAtom)
  const loadPlaces = async (keyword: string) => {
    return searchPlaceListApi(1, 10000, keyword, customer?.id).then((data) => {
      return data.items.map((item) => {
        return { text: item.title, value: item.id }
      })
    })
  }
  const loadDocuments = async (keyword: string) => {
    return getDocumentsApi(
      { page: 1, limit: 50, searchField: 'title', keyword },
      customer?.id
    ).then((data) => {
      return data.items.map((item) => {
        return { text: item.title, value: item.id }
      })
    })
  }

  useEffect(() => {
    setCurrentContentType(contentType || 'document')
  }, [contentType])

  return (
    <>
      <SelectSideLabelForm
        id="type"
        name="type"
        label="컨텐츠 종류"
        options={types}
        onChange={(e) => {
          setCurrentContentType(e.target.value)
        }}
        defaultValue="document"
      />
      {currentContentType === 'document' && (
        <Finder
          id="documentId"
          name="documentId"
          label="문서 선택"
          searchCallback={loadDocuments}
          defaultItem={document}
        />
      )}
      {currentContentType === 'place' && (
        <Finder
          id="placeId"
          name="placeId"
          label="장소 선택"
          searchCallback={loadPlaces}
          defaultItem={place}
        />
      )}
      {currentContentType === 'link' && (
        <InputSideLabelForm
          type="url"
          id="contents"
          name="contents"
          label="링크"
          placeholder="ex) https://everyoncorp.com"
          className={`${currentContentType === 'link' ? '' : 'hidden'}`}
        />
      )}
    </>
  )
}
