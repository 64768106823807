import { selector, useRecoilRefresher_UNSTABLE } from 'recoil'
import { getSurveyVersionListApi } from '../api/survey.api'
import { SurveyVersion } from '../types/Survey'
import { customerAtom } from './customer.recoil'

// versions
export const versionsSelector = selector<SurveyVersion[]>({
  key: 'surveyVersionListSelector',
  get: ({ get }) => {
    const customer = get(customerAtom)
    if (!customer) return []
    return getSurveyVersionListApi({ page: 1, limit: 100 }, customer.id).then(
      (data) => data.items
    )
  },
})

export function useSurveyVersionsRefresh() {
  const versionsSelectorRefresh = useRecoilRefresher_UNSTABLE(versionsSelector)
  const refresh = () => {
    versionsSelectorRefresh()
  }
  return refresh
}

export default {}
