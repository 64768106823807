import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getSignBoardListApi } from '../api/sign-board.api'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import Select from '../components/select/Select'
import { CustomerSelector } from '../components/survey/ListFilters'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import { signBoardTypeToText } from '../helpers/place.helper'
import useAuth from '../hooks/auth.hook'
import { customerAtom } from '../recoil/customer.recoil'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TheadStyle,
  TbodyStyle,
  TdStyle,
  ThStartStyle,
  ThStyle,
  ThEndStyle,
  TdStartStyle,
  TdEndStyle,
} from '../styles/table.style'
import { SignBoardModel } from '../types/SignBoard'

function SignBoardList() {
  const { isAdmin } = useAuth()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [signBoardList, setSignBoardList] = useState<SignBoardModel[]>([])
  const [hasFile, setHasFile] = useState<boolean | undefined>()
  const hasFileRef = useRef<HTMLSelectElement>(null)
  const [limit, setLimit] = useState(20)

  // filters
  const customer = useRecoilValue(customerAtom)

  const handleChangeHasFileFilter = (value?: boolean) => {
    setHasFile(value)
  }

  const renderItem = (signBoard: SignBoardModel, i: number) => {
    const num = (page - 1) * limit + i + 1
    return (
      <tr key={signBoard.id}>
        <td className={classNames(TdStartStyle)}>{num}</td>
        <td className={classNames(TdStyle)}>{signBoard.id}</td>
        <td className={classNames(TdStyle)}>{signBoard.code}</td>
        <td className={classNames(TdStyle)}>
          <div className="flex items-center">
            {signBoard?.icon?.path && (
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded"
                  src={`${signBoard.icon?.path}`}
                  alt={`${signBoard.placeTitle} 아이콘`}
                />
              </div>
            )}
            <div className="flex flex-row gap-2 items-end">
              <span className="font-bold">{signBoard.placeTitle}</span>
              <span className="flex flex-row gap-0.5 text-xs text-teal-400">
                <PhotoIcon className="w-4" />
                {signBoard.files?.length}
              </span>
            </div>
          </div>
        </td>
        <td className={classNames(TdStyle)}>
          {signBoardTypeToText(signBoard.signBoardType)}
        </td>
        <td className={classNames(TdStyle)}>{signBoard.place?.floor?.title}</td>
        <td className={classNames(TdStyle)}>{signBoard.place?.title}</td>
        <td className={classNames(TdEndStyle, 'flex justify-end space-x-4')}>
          <Link
            to={`/sign-board/view/${signBoard.id}`}
            className="text-teal-600 hover:text-teal-900"
          >
            상세 <span className="sr-only">, {signBoard.placeTitle}</span>
          </Link>
          <Link
            to={`/sign-board/edit/${signBoard.id}`}
            className="text-teal-600 hover:text-teal-900"
          >
            수정
            <span className="sr-only">, {signBoard.placeTitle}</span>
          </Link>
        </td>
      </tr>
    )
  }

  useEffect(() => {
    document.title = `안내판 관리 - ${ADMIN_TITLE}`
    getSignBoardListApi({ page, limit }, customer?.id, hasFile).then((data) => {
      setSignBoardList(data.items)
      setTotal(data.total)
    })
  }, [customer, page, hasFile, limit])
  return (
    <ManageListLayout
      title="안내판 관리"
      description="안내판을 관리하는 페이지 입니다."
      createButtonChildren={
        <Link to="/sign-board/create" className={PrimaryButtonStyle}>
          안내판 생성하기
        </Link>
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={(p: number) => setPage(p)}
        />
      }
    >
      <div className="flex flex-col sm:flex-col sm:justify-between sm:items-end md:flex-row gap-2 pb-2">
        <div className="flex flex-row gap-2">
          {isAdmin && (
            <CustomerSelector
              onChange={() => {
                if (hasFileRef && hasFileRef.current)
                  hasFileRef.current.value = 'all'
              }}
            />
          )}
          <Select
            label="페이지당 건수"
            options={['5', '10', '20', '50', '100'].map((item) => ({
              text: item,
              value: item,
            }))}
            defaultValue={20}
            onChange={(e) => {
              setLimit(Number(e.target.value))
              setPage(1)
            }}
          />
          <Select
            label="이미지 첨부 여부"
            selectRef={hasFileRef}
            options={[
              { text: '전체', value: 'all' },
              { text: '첨부됨', value: 'true' },
              { text: '첨부안됨', value: 'false' },
            ]}
            onChange={(e) => {
              const { value } = e.target
              const newValue: boolean | undefined =
                value === 'all' ? undefined : value === 'true'
              handleChangeHasFileFilter(newValue)
            }}
          />
        </div>
        <span className="text-slate-700">전체 수: {total}</span>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    ID
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    관리코드
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    이름
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    종류
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    층
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    연결된 장소
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {signBoardList.length === 0 && (
                  <tr>
                    <td colSpan={7} className={classNames(TdStartStyle)}>
                      데이터가 없습니다.
                    </td>
                  </tr>
                )}
                {signBoardList.map((signBoard, i) => renderItem(signBoard, i))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default SignBoardList
