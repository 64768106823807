import React, { useEffect, useState } from 'react'
import { getFloorsApi } from '../../api/survey.api'
import { sortFloor } from '../../helpers/place.helper'
import useSurvey from '../../hooks/survey.hook'
import Select, { OptionProps } from '../select/Select'

export type CustomerSelectorProps = {
  hideLabel?: boolean
  onChange?: () => void
}
export function CustomerSelector({
  hideLabel,
  onChange,
}: CustomerSelectorProps) {
  const { customer, setCustomer, customers } = useSurvey()
  useEffect(() => {
    if (!customer) {
      setCustomer(customers[0])
    }
  }, [customer, customers, setCustomer])

  return (
    <Select
      id="select-customer"
      label={hideLabel ? '' : '업체 선택'}
      options={customers.map((item) => ({ text: item.name, value: item.id }))}
      defaultValue={customer?.id}
      onChange={(e) => {
        setCustomer(customers.find((item) => item.id === e.target.value))
        if (onChange) onChange()
      }}
    />
  )
}

function BasePlaceSelector() {
  const { basePlace, setBasePlace, basePlaceList } = useSurvey()
  useEffect(() => {
    if (!basePlace) {
      setBasePlace(basePlaceList[0])
    }
  }, [basePlace, basePlaceList, setBasePlace])
  return (
    <Select
      id="select-place"
      label="기본 장소/건물 선택"
      options={basePlaceList.map((item) => ({
        text: item.title,
        value: item.id,
      }))}
      onChange={(e) => {
        setBasePlace(basePlaceList.find((item) => item.id === e.target.value))
      }}
    />
  )
}

function VersionSelector() {
  const { version, setVersion, versions } = useSurvey()
  useEffect(() => {
    if (!version) {
      setVersion(versions[0])
    }
  }, [version, versions, setVersion])
  return (
    <Select
      id="select-version"
      label="조사버전 선택"
      defaultValue={version?.id}
      options={versions.map((item) => ({
        text: item.name,
        value: `${item.id}`,
      }))}
      onChange={(e) => {
        setVersion(versions.find((item) => `${item.id}` === e.target.value))
      }}
    />
  )
}

export type FloorSelectorProps = {
  onChange: (value: string) => void
}
function FloorSelector({ onChange }: FloorSelectorProps) {
  const [floors, setFloors] = useState<OptionProps[]>([
    { text: '전체', value: 'all' },
  ])
  const { version } = useSurvey()

  useEffect(() => {
    if (version) {
      getFloorsApi({ page: 1, limit: 100 }, version.id).then((data) =>
        setFloors([
          { text: '전체', value: 'all' },
          ...data.items
            .sort((a, b) => sortFloor(a.title, b.title))
            .map((item) => ({
              text: item.title,
              value: item.floorId,
            })),
        ])
      )
    }
  }, [version])

  return (
    <Select
      label="층"
      options={floors}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export type ListFiltersProps = {
  onChangeFloorSelector: (value: string) => void
}
function ListFilters({ onChangeFloorSelector }: ListFiltersProps) {
  const { customer } = useSurvey()
  return (
    <div className="flex flex-col sm:flex-col md:flex-row gap-2 ">
      <CustomerSelector />
      {customer && <VersionSelector />}
      {customer && <BasePlaceSelector />}
      {customer && <FloorSelector onChange={onChangeFloorSelector} />}
    </div>
  )
}

export default ListFilters
