import Joi from 'joi'
import validator from '../lib/validate'

export const LoginValidation = validator().object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } }),
  password: Joi.string().required().min(8),
})

export default { LoginValidation }
