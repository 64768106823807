import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import { CreateCustomerRequest } from '../types/api/customer'
import { CustomerType } from '../types/Customer'

export const getCustomersApi = async ({
  page,
  limit,
  searchField,
  keyword,
}: ListRequestDto): Promise<ListDto<CustomerType>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  return api.get(`/customers?page=${page}&limit=${limit}${searchQuery}`)
}

export const getCustomerApi = async (id: string): Promise<CustomerType> => {
  return api.get(`/customers/${id}`)
}

export const createCustomerApi = async (
  data: CreateCustomerRequest
): Promise<CustomerType> => {
  return api.post(`/customers`, data)
}

export const updateCustomerApi = async (
  id: string,
  data: CreateCustomerRequest
): Promise<CustomerType> => {
  return api.patch(`/customers/${id}`, data)
}

export const deleteCustomerApi = async (id: string): Promise<void> => {
  return api.delete(`/customers/${id}`)
}

export default { customerListApi: getCustomersApi, createCustomerApi }
