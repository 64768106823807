/* eslint-disable import/no-cycle */
/* eslint import/no-cycle: [2, { ignoreExternal: true }] */
import { CustomerType } from './Customer'
import { DocumentModel } from './Document'
import { Place } from './Place'
import { SignBoardModel } from './SignBoard'

export enum NfcContentTypeEnum {
  DOCUMENT = 'document',
  PLACE = 'place',
  SIGNBOARD = 'signBoard',
  LINK = 'link',
}

export interface Nfc {
  id: string
  code?: string
  serviceId: string
  customer?: CustomerType
  name: string
  contents: NfcContents[]
}

export interface NfcContents {
  id: string
  type: NfcContentTypeEnum
  contentId?: string
  contents?: string
  documentId?: string
  placeId?: string
  document?: DocumentModel
  place?: Place
  signBoard?: SignBoardModel
}
