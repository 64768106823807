import React, { InputHTMLAttributes, ReactNode } from 'react'

import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../error-message/ErrorMessage'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  name: string
  label: string
  children?: ReactNode
}

export default function InputSideLabelForm(props: InputProps): JSX.Element {
  const {
    name,
    id,
    label,
    type,
    placeholder,
    readOnly,
    children,
    disabled,
    required,
    defaultValue,
  } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0 space-y-1">
        <div className="relative">
          <input
            type={type}
            id={id}
            autoComplete={name}
            required={false}
            placeholder={placeholder}
            aria-invalid="true"
            aria-describedby={`${name}-error`}
            readOnly={readOnly}
            disabled={disabled}
            className="block w-full max-w-lg rounded-md border-slate-300 focus:border-teal-500 focus:ring-teal-500 sm:text-sm disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
            {...formMethods.register(name)}
            defaultValue={defaultValue}
          />
          {children}
        </div>
        <ErrorMessage error={error} name={name} />
      </div>
    </div>
  )
}
