import { selector } from 'recoil'
import { getLoginUser } from '../lib/auth'
import { RoleType } from '../types/Role'
import { AdminUser } from '../types/User'

export default {}

export const loginUserSelector = selector<AdminUser | null>({
  key: 'loginUserSelector',
  get: () => getLoginUser(),
})

export const isAdminSelector = selector<boolean>({
  key: 'isAdminSelector',
  get: ({ get }) => {
    const user = get(loginUserSelector)
    return !!user?.roles.find((role) => role.id === RoleType.ADMIN)
  },
})
