import { LinkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import { classNames } from '../../helpers/common.helper'
import { TdEndStyle, TdStartStyle, TdStyle } from '../../styles/table.style'
import { Place } from '../../types/Place'

type PlaceItemProp = {
  id?: string
  nextLink?: string
  linkLabel?: string
  children?: React.ReactNode
  place?: Place
  showCustomer?: boolean
  num?: number
}

function PlaceItem({
  id,
  nextLink,
  linkLabel,
  children,
  showCustomer = false,
  place,
  num,
}: PlaceItemProp): JSX.Element {
  return (
    <tr>
      <td className={classNames(TdStartStyle)}>{num}</td>
      <td className={classNames(TdStyle)}>
        <div className="flex items-center">
          <div>{children}</div>
          {place?.icon?.path && (
            <div className="h-10 w-10 flex-shrink-0 ml-4">
              <img
                className="h-10 w-10 rounded"
                src={`${place.icon?.path}`}
                alt={`${place.title} 아이콘`}
              />
            </div>
          )}
        </div>
      </td>
      {showCustomer && (
        <td className={classNames(TdStyle)}>{place?.customer?.name}</td>
      )}
      <td className={classNames(TdStyle)}>
        {place?.available ? '사용' : '미사용'}
      </td>
      <td className={classNames(TdEndStyle, 'flex justify-end space-x-4')}>
        <a
          href={`${process.env.REACT_APP_SCAN_URL}/place/${id}`}
          className="flex items-center text-teal-600 hover:text-teal-900"
          target="_blank"
          rel="noreferrer"
        >
          내용보기
          <span className="sr-only">, {children}</span>
          <LinkIcon className="w-4 h-4" aria-label="새창으로 열기" />
        </a>
        <Link to={nextLink || ''} className="text-teal-600 hover:text-teal-900">
          {linkLabel}
          <span className="sr-only">, {children}</span>
        </Link>
        <Link
          to={`/place/edit/${id}`}
          className="text-teal-600 hover:text-teal-900"
        >
          수정<span className="sr-only">, {children}</span>
        </Link>
      </td>
    </tr>
  )
}

export default PlaceItem
