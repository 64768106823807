import React, { useCallback, useEffect, useState } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { ADMIN_TITLE } from '../constants/title'
import Button from '../components/button/Button'
import { showAlert } from '../lib/alert'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import {
  createSignBoardApi,
  deleteSignBoardApi,
  getSignBoardApi,
  signBoardFileDelete,
  signBoardUploadApi,
  updateSignBoardApi,
} from '../api/sign-board.api'
import SelectSideLabelForm from '../components/forms/SelectSideLabel'
import { SignBoardTypes } from '../enums/place.enum'
import Finder, { FinderItem } from '../components/finders/Finder'
import { customerAtom, customersSelector } from '../recoil/customer.recoil'
import { searchPlaceListApi } from '../api/place.api'
import {
  CreateSignBoardRequest,
  UpdateSignBoardRequest,
} from '../types/api/sign-board'
import { SignboardCreateValidation } from '../validations/signboard.validation'
import ImageList from '../components/lists/ImageList'
import SideLabel from '../components/forms/SideLabel'
import { FileModel } from '../types/File'
import useAuth from '../hooks/auth.hook'
import FileForm from '../components/forms/FileForm'
import ImageSelector from '../components/select/ImageSelector2'
import { getPlaceIconListApi } from '../api/place-icon.api'
import { SignBoardModel } from '../types/SignBoard'
import AlertCheckIcon from '../components/notifications/AlertCheckIcon'
import AlertWarningIcon from '../components/notifications/AlertWarningIcon'

function SignBoardCreate(): JSX.Element {
  const { isAdmin } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()
  const [signBoard, setSignBoard] = useState<SignBoardModel>()
  const methods = useForm({
    resolver: joiResolver(SignboardCreateValidation),
  })
  const [title, setTitle] = useState('안내판 생성')
  const [files, setFiles] = useState<FileModel[]>([])
  const customer = useRecoilValue(customerAtom)
  const [customerId, setCustomerId] = useState(customer?.id)
  const customers = useRecoilValue(customersSelector)

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      updateSignBoardApi(id, {
        ...formData,
        iconId: formData.iconId || undefined,
      } as UpdateSignBoardRequest).then(() => {
        navigate('/sign-board')
      })
    } else {
      createSignBoardApi({
        ...formData,
        iconId: formData.iconId || undefined,
      } as CreateSignBoardRequest).then(() => {
        navigate('/sign-board')
      })
    }
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteSignBoardApi(id)
          navigate('/sign-board')
        },
      },
    })
  }

  const handlePlaceChange = async (item: FinderItem) => {
    methods.setValue('placeTitle', item.text)
    methods.setValue('dotPlaceTitle', item.text)
  }

  const loadPlaceList = useCallback(
    async (keyword: string) => {
      return searchPlaceListApi(1, 10000, keyword, customerId).then((data) => {
        return data.items.map((item) => {
          return { text: item.title, value: item.id }
        })
      })
    },
    [customerId]
  )

  const fileUpload = useCallback(
    async (fileList: File[]) => {
      if (id) {
        const sb = await signBoardUploadApi(id, fileList)
        if (sb.files) setFiles(sb.files)
        showAlert('업로드 완료', {
          icon: <AlertCheckIcon />,
        })
      }
    },
    [id]
  )

  const handleFileDeleteButton = (signBoardId: string, fileId: string) => {
    showAlert('정말로 삭제하시겠습니까?', {
      icon: <AlertWarningIcon />,
      confirm: {
        confirmClick: async () => {
          try {
            await signBoardFileDelete(signBoardId, fileId)
            setFiles([...files.filter((file) => file.id !== fileId)])
          } catch (ex) {
            showAlert('파일 삭제 실패')
          }
        },
      },
    })
  }

  useEffect(() => {
    document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('안내판 수정')
      getSignBoardApi(id).then((data) => {
        methods.setValue('customerId', data.customerId)
        methods.setValue('code', data.code)
        methods.setValue('placeTitle', data.placeTitle)
        methods.setValue('signBoardType', data.signBoardType)
        methods.setValue('placeId', data.place?.id)
        methods.setValue('dotPlaceTitle', data.dotPlaceTitle)

        setCustomerId(data.customerId)
        setSignBoard(data)
        setFiles(data.files || [])
      })
    } else if (customer) {
      setCustomerId(customer.id)
      methods.setValue('customerId', customer.id)
    } else {
      setCustomerId(customers[0].id)
    }
  }, [id, methods, title, customer, customers])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="안내판을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/sign-board" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
    >
      {id && <SideLabel label="ID">{id}</SideLabel>}
      <SelectSideLabelForm
        id="customerId"
        name="customerId"
        label="업체"
        disabled={!!id}
        options={customers.map((data) => ({ text: data.name, value: data.id }))}
        onChange={(e) => setCustomerId(e.target.value)}
      />

      <InputSideLabelForm
        id="code"
        name="code"
        type="text"
        label="관리코드"
        disabled
      />

      <Finder
        id="placeId"
        name="placeId"
        label="연결된 장소"
        searchCallback={loadPlaceList}
        selectCallback={handlePlaceChange}
        defaultItem={
          signBoard?.place && {
            text: signBoard.place.title,
            value: signBoard.place.id,
          }
        }
      />
      <InputSideLabelForm
        id="placeTitle"
        name="placeTitle"
        type="text"
        label="이름"
      />
      <InputSideLabelForm
        id="dotPlaceTitle"
        name="dotPlaceTitle"
        type="text"
        label="점자 타이틀"
      />
      <SelectSideLabelForm
        id="signBoardType"
        name="signBoardType"
        label="종류"
        options={SignBoardTypes}
      />
      <SideLabel label="아이콘 선택">
        <ImageSelector
          name="iconId"
          defaultItem={
            signBoard?.icon && {
              ...signBoard.icon,
              label: signBoard.icon.iconName,
            }
          }
          onSelectCallback={(iconId: string) => {
            methods.setValue('iconId', iconId)
          }}
          onSearchCallback={async (keyword: string) => {
            const placeIcons = await getPlaceIconListApi({
              page: 1,
              limit: 100,
              keyword,
            })
            return placeIcons.items.map((item) => ({
              ...item,
              label: item.iconName,
            }))
          }}
        />
      </SideLabel>
      <SideLabel label="설치 사진">
        <div className="flex flex-col gap-4">
          <ImageList
            items={files.sort((a, b) => {
              if (!a.createdAt || !b.createdAt) return -1
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              )
            })}
            onDeleteCallback={(fileId: string) => {
              if (!id) return
              handleFileDeleteButton(id, fileId)
            }}
          />
          {id && isAdmin && (
            <FileForm
              id="file"
              name="file"
              type="file"
              onDropCallback={fileUpload}
            />
          )}
        </div>
      </SideLabel>
    </ManageCreateLayout>
  )
}

export default SignBoardCreate
