import { atom, selector } from 'recoil'
import { getCustomersApi } from '../api/customer.api'
import { CustomerType } from '../types/Customer'
import { isAdminSelector, loginUserSelector } from './login-user.recoil'

export const customerAtom = atom<CustomerType | undefined>({
  key: 'customerAtom',
  default: undefined,
})

export const customersSelector = selector<CustomerType[]>({
  key: 'customersSelector',
  get: () =>
    getCustomersApi({ page: 1, limit: 1000 }).then((data) => {
      return data.items
    }),
})

export const usableCustomersSelector = selector<CustomerType[]>({
  key: 'usableCustomersSelector',
  get: ({ get }) => {
    const user = get(loginUserSelector)
    const customers = get(customersSelector)
    const isAdmin = get(isAdminSelector)
    if (isAdmin) return customers
    return user?.customers || []
  },
})
