import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  getPublishesApi,
  getSurveyListApi,
  publishApi,
  toggleAvailableApi,
} from '../api/survey.api'
import Button from '../components/button/Button'
import SearchInput from '../components/inputs/SearchInput'
import ManageListLayout from '../components/layout/ManageListLayout'
import AlertCheckIcon from '../components/notifications/AlertCheckIcon'
import AlertWarningIcon from '../components/notifications/AlertWarningIcon'
import SimpleAlert from '../components/notifications/SimpleAlert'
import Paginate from '../components/paginate'
import Select from '../components/select/Select'
import ListFilters from '../components/survey/ListFilters'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import { placeCategoryToText, placeTypeToText } from '../helpers/place.helper'
import useSurvey from '../hooks/survey.hook'
import { showAlert } from '../lib/alert'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TheadStyle,
  ThStyle,
  ThEndStyle,
  TbodyStyle,
  ThStartStyle,
  TdStartStyle,
  TdStyle,
  TdEndStyle,
} from '../styles/table.style'
import { Survey } from '../types/Survey'

function SurveyList() {
  const [surveyList, setSurveyList] = useState<Survey[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [sortField, setSortField] = useState<string>('createdAt')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [limit, setLimit] = useState(20)

  // filters
  const { customer, basePlace, version, setVersion } = useSurvey()
  const [floorId, setFloorId] = useState<string>('all')

  const getSurveyList = useCallback(
    async (searchField?: string, keyword?: string) => {
      if (!customer || !version) {
        setSurveyList([])
        setTotal(0)
        return
      }
      getSurveyListApi(
        {
          page,
          limit,
          searchField,
          keyword,
          sortField,
          sortDirection,
        },
        customer?.id,
        version ? Number(version.id) : undefined,
        basePlace?.id,
        floorId === 'all' ? undefined : floorId
      ).then((data) => {
        setSurveyList(data.items)
        setTotal(data.total)
      })
    },
    [
      page,
      customer,
      version,
      basePlace,
      sortField,
      sortDirection,
      floorId,
      limit,
    ]
  )
  const handleOnChangePage = (p: number) => setPage(p)
  const handleOnSearch = (searchField?: string, keyword?: string) => {
    getSurveyList('placeTitle', keyword)
  }
  const handlePublish = async () => {
    if (!customer) {
      showAlert('선택된 업체가 없습니다.')
      return
    }
    if (!version) {
      showAlert('선택된 버전이 없습니다.')
      return
    }

    const { total: availableCountTotal, available } = await getPublishesApi(
      version.id
    )

    if (available === 0) {
      showAlert('생성할 데이터가 없습니다.')
      return
    }

    showAlert(
      `현장조사 총 ${availableCountTotal}건중 ${available}건에 대한 장소, 안내판 데이터를 생성하시겠습니까`,
      {
        icon: <AlertWarningIcon />,
        confirm: {
          confirmClick: () => {
            publishApi(version.id).then((data) => {
              showAlert('장소와 안내판 데이터가 생성 되었습니다.', {
                icon: <AlertCheckIcon />,
              })
              setVersion(data.version)
            })
          },
        },
      }
    )
  }

  useEffect(() => {
    document.title = `현장조사 관리 - ${ADMIN_TITLE}`
    getSurveyList()
  }, [getSurveyList])
  return (
    <ManageListLayout
      title="현장조사 데이터 관리"
      description="현장조사 데이터를 관리하는 페이지 입니다."
      createButtonChildren={
        !version?.publishedAt && (
          <Link to="/survey/create" className={PrimaryButtonStyle}>
            현장조사 생성하기
          </Link>
        )
      }
      searchChildren={
        <SearchInput className="w-30" onSearch={handleOnSearch} />
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
      tailChildren={
        !version?.publishedAt && (
          <div className="flex justify-end">
            <Button
              className={PrimaryButtonStyle}
              onClick={handlePublish}
              disabled={!!version?.publishedAt || false}
            >
              데이터 생성하기
            </Button>
          </div>
        )
      }
    >
      <div className="flex flex-col gap-2">
        <ListFilters
          onChangeFloorSelector={(value: string) => setFloorId(value)}
        />
        <div className="flex flex-col justify-start gap-2 sm:flex-row sm:justify-between sm:items-end">
          <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-end">
            <Select
              onChange={(e) => setSortField(e.target.value)}
              label="정렬"
              options={[{ text: '생성일', value: 'createdAt' }]}
            />
            <Select
              onChange={(e) =>
                setSortDirection(e.target.value as 'ASC' | 'DESC')
              }
              options={[
                { text: '오름차순', value: 'ASC' },
                { text: '내림차순', value: 'DESC' },
              ]}
            />
            <Select
              label="페이지당 건수"
              options={['5', '10', '20', '50', '100'].map((item) => ({
                text: item,
                value: item,
              }))}
              defaultValue={20}
              onChange={(e) => {
                setLimit(Number(e.target.value))
                setPage(1)
              }}
            />
          </div>
          <div className="text-slate-700">전체 수: {total}</div>
        </div>
      </div>
      {version?.publishedAt && (
        <SimpleAlert className="my-2">
          사전데이터 기반으로 이미 데이터를 생성하였습니다.
          <br />
          보기만 가능합니다.
        </SimpleAlert>
      )}
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    층
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    ID
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    장소명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    장소 유형
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    장소 세부 유형
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    사용여부
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {surveyList.length === 0 && (
                  <tr>
                    <td colSpan={4} className={classNames(TdStartStyle)}>
                      등록된 데이터가 없습니다
                    </td>
                  </tr>
                )}
                {surveyList.map((survey, i: number) => (
                  <tr key={survey.id}>
                    <td className={classNames(TdStartStyle)}>
                      {(page - 1) * limit + i + 1}
                    </td>
                    <td className={classNames(TdStyle)}>
                      {survey.floor?.title}
                    </td>
                    <td className={classNames(TdStyle)}>{survey.id}</td>
                    <td className={classNames(TdStyle)}>{survey.placeTitle}</td>
                    <td className={classNames(TdStyle)}>
                      {placeTypeToText(survey.placeType)}
                    </td>
                    <td className={classNames(TdStyle)}>
                      {placeCategoryToText(survey?.placeCategory)}
                    </td>
                    <td className={classNames(TdStyle)}>
                      <input
                        disabled={!!version?.publishedAt}
                        type="checkbox"
                        defaultChecked={survey.available}
                        onChange={() => {
                          toggleAvailableApi(survey.id)
                        }}
                      />
                    </td>
                    <td
                      className={classNames(
                        TdEndStyle,
                        'flex justify-end space-x-4'
                      )}
                    >
                      <Link
                        to={`/survey/view/${survey.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        상세
                        <span className="sr-only">, {survey.placeTitle}</span>
                      </Link>
                      <Link
                        to={`/survey/edit/${survey.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        {!version?.publishedAt ? '수정' : '보기'}
                        <span className="sr-only">, {survey.placeTitle}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default SurveyList
