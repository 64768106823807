import jwt_decode from 'jwt-decode'
// eslint-disable-next-line import/no-cycle
import { loginApi } from '../api/login.api'
import { ACCESS_TOKEN, LOGIN_USER } from '../constants/auth.constant'
import { LoginRequest, LoginResponse } from '../types/api/login'
import { AdminUser } from '../types/User'
import { showAlert } from './alert'

export const login = async (req: LoginRequest): Promise<AdminUser | null> => {
  try {
    const authDto: LoginResponse = await loginApi(req)
    localStorage.setItem(LOGIN_USER, JSON.stringify(authDto.user))
    localStorage.setItem(ACCESS_TOKEN, authDto.accessToken)
    return authDto.user
  } catch (ex: any) {
    switch (ex.status) {
      case 404:
        showAlert('일치하는 정보가 없습니다.')
        break
      default:
        break
    }
  }
  return null
}

export const logout = () => {
  localStorage.removeItem(LOGIN_USER)
  localStorage.removeItem(ACCESS_TOKEN)
}

export const isExpired = (): boolean => {
  const tokenStr = localStorage.getItem(ACCESS_TOKEN) || ''
  const token = tokenStr ? jwt_decode<AdminUser>(tokenStr) : null
  if (!token) return true
  return token.exp * 1000 < new Date().getTime()
}

export const getLoginUser = (): AdminUser | null => {
  const user = localStorage.getItem(LOGIN_USER)
  if (isExpired()) {
    logout()
    return null
  }
  if (!user || user === 'undefined') return null
  return JSON.parse(user) as AdminUser
}

export default { login, getLoginUser, logout }
