import React, { HTMLAttributes } from 'react'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'
import Form from '../forms/Form'

type ManageCreateLayoutProps = HTMLAttributes<HTMLElement> & {
  title: string
  description?: string
  children?: React.ReactNode
  cancelButton?: React.ReactNode
  saveButton?: React.ReactNode
  delButton?: React.ReactNode
  methods: UseFormReturn
  onSubmit: SubmitHandler<FieldValues>
}

function ManageCreateLayout({
  title,
  description,
  children,
  cancelButton,
  saveButton,
  delButton,
  methods,
  onSubmit,
}: ManageCreateLayoutProps) {
  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <div className="py-6 px-4 sm:px-6 lg:px-8 space-y-8 divide-y divide-slate-200">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-slate-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-slate-500">
              {description}
            </p>
          </div>
          {children}
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            {cancelButton}
            <div className="flex-1 flex justify-end gap-2">
              {delButton}
              {saveButton}
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ManageCreateLayout
