import React, { HTMLAttributes, InputHTMLAttributes, useCallback } from 'react'
import useSurvey from '../../hooks/survey.hook'
import Finder2, { Finder2Item } from './Finder2'

type BasePlaceFinderProps = InputHTMLAttributes<HTMLInputElement>

function BasePlaceFinder({ disabled, required }: BasePlaceFinderProps) {
  const { basePlace, basePlaceList } = useSurvey()
  const handleSearch = useCallback(
    async (keyword: string): Promise<Finder2Item[]> => {
      return basePlaceList
        .filter((item) => item.title.indexOf(keyword.toLowerCase()) > -1)
        .map((item) => ({ text: item.title, value: item.id }))
    },
    [basePlaceList]
  )

  return (
    <Finder2
      label="기본 장소/건물 선택"
      name="basePlaceId"
      onSearch={handleSearch}
      defaultItem={basePlace && { text: basePlace.title, value: basePlace.id }}
      disabled={disabled}
      required={required}
    />
  )
}

export default BasePlaceFinder
