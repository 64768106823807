import React, { RefObject, SelectHTMLAttributes } from 'react'

export interface OptionProps {
  text: string
  value: string
}
interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  options?: OptionProps[]
  selectRef?: RefObject<HTMLSelectElement>
}

export default function Select({
  label,
  options,
  id,
  onChange,
  defaultValue,
  selectRef,
}: SelectProps) {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-slate-700 pl-1 pr-4"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        className="mt-1 block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
        onChange={onChange}
        defaultValue={defaultValue}
        ref={selectRef}
      >
        {options?.map((item) => {
          return (
            <option key={`${id}-${item.value}`} value={item.value}>
              {item.text}
            </option>
          )
        })}
      </select>
    </div>
  )
}
