import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useFormContext } from 'react-hook-form'
import { classNames, Debounce } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

export type FinderItem = {
  value: string
  text: string
}

type FinderProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  name: string
  label: string
  searchCallback?: (
    keyword: string
  ) => Promise<{ value: string; text: string }[]>
  selectCallback?: (item: FinderItem) => Promise<void>
  defaultItem?: FinderItem
}

function Finder({
  name,
  label,
  searchCallback,
  selectCallback,
  className,
  defaultItem,
  required,
  disabled,
}: FinderProps) {
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const [items, setItems] = useState<FinderItem[]>([])
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<FinderItem | undefined>(
    undefined
  )
  const filteredItems = items

  const debounce = useMemo(() => new Debounce(500), [])
  const handleSelectItem = (item: FinderItem) => {
    setSelectedItem(item)
    formMethods.setValue(name, item.value)
    if (selectCallback) selectCallback(item)
  }
  const search = useCallback(async () => {
    debounce.run(async () => {
      if (searchCallback) setItems(await searchCallback(query))
    })
  }, [debounce, query, searchCallback])

  useEffect(() => {
    search()
    setSelectedItem(defaultItem)
  }, [search, defaultItem])

  return (
    <Combobox
      as="div"
      value={selectedItem}
      {...formMethods.register(name)}
      onChange={handleSelectItem}
      className={classNames(
        'sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5',
        className
      )}
      disabled={disabled}
    >
      <Combobox.Label className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2 align-middle">
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-slate-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 sm:text-sm disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: FinderItem) => item?.text}
          onFocus={() => {
            search()
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems
              .filter(
                (item) =>
                  item.text.toLowerCase().indexOf(query.toLowerCase()) > -1
              )
              .map((item) => (
                <Combobox.Option
                  key={item.value}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-teal-600 text-white' : 'text-slate-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {item.text}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-teal-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
      </div>
      <ErrorMessage error={error} name={name} />
    </Combobox>
  )
}

export default Finder
