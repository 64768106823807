import { joiResolver } from '@hookform/resolvers/joi'
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  createSurveyVersionApi,
  deleteSurveyVersionApi,
  getSurveyVersionApi,
  updateSurveyVersionApi,
} from '../api/survey.api'
import Button from '../components/button/Button'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import SideLabel from '../components/forms/SideLabel'
import TextAreaSideLabelForm from '../components/forms/TextareaSideLabel'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import { ADMIN_TITLE } from '../constants/title'
import useSurvey from '../hooks/survey.hook'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import {
  CreateSurveyVersionRequest,
  UpdateSurveyVersionRequest,
} from '../types/api/survey'
import { SurveyVersionCreateValidation } from '../validations/survey.validation'

function SurveyVersionCreate() {
  const methods = useForm({
    resolver: joiResolver(SurveyVersionCreateValidation),
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const [title, setTitle] = useState('현장조사 버전 생성')
  const { refreshVersions: refreshVersion } = useSurvey()

  useEffect(() => {
    document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('현장조사 버전 수정')
      getSurveyVersionApi(id).then((data) => {
        methods.setValue('name', data.name)
        methods.setValue('customerId', data.customerId)
        methods.setValue('description', data.description)
      })
    }
  }, [id, methods, title])

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updateSurveyVersionApi(id, formData as UpdateSurveyVersionRequest)
    } else {
      await createSurveyVersionApi(formData as CreateSurveyVersionRequest)
    }
    refreshVersion()
    navigate('/survey-version')
  }
  const handleDeleteButton = () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteSurveyVersionApi(id)
          refreshVersion()
          navigate('/survey-version')
        },
      },
    })
  }
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="현장조사 버전을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/survey-version" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      {id && <SideLabel label="ID">{id}</SideLabel>}
      <CustomerSelectorSideLabelForm required />
      <InputSideLabelForm
        id="name"
        name="name"
        type="text"
        label="버전 이름"
        required
      />
      <TextAreaSideLabelForm id="description" name="description" label="설명" />
    </ManageCreateLayout>
  )
}

export default SurveyVersionCreate
