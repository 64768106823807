import React, { useEffect, useState } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import {
  createAdminUserApi,
  deleteAdminUserApi,
  getAdminUserApi,
  updateAdminUserApi,
} from '../api/admin-user.api'
import { ADMIN_TITLE } from '../constants/title'
import {
  CreateAdminUserRequest,
  UpdateAdminUserRequest,
} from '../types/api/admin-user'
import {
  UserCreateValidation,
  UserUpdateValidation,
} from '../validations/user.validation'
import Button from '../components/button/Button'
import { showAlert } from '../lib/alert'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import CustomerSelector from '../components/admin-users/CustomerSelector'
import {
  selectedCustomerAtom,
  selectedRoleAtom,
} from '../recoil/user-create.recoil'
import { RoleType } from '../types/Role'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import SideLabel from '../components/forms/SideLabel'

function AdminUserCreate(): JSX.Element {
  const navigate = useNavigate()
  const { id } = useParams()
  const methods = useForm({
    resolver: joiResolver(id ? UserUpdateValidation : UserCreateValidation),
  })
  const [title, setTitle] = useState('관리자 생성')
  const setSelectedRole = useSetRecoilState(selectedRoleAtom)
  const setSelectedCustomer = useSetRecoilState(selectedCustomerAtom)
  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updateAdminUserApi(id, formData as UpdateAdminUserRequest)
    } else {
      await createAdminUserApi(formData as CreateAdminUserRequest)
    }
    navigate('/admin-users')
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteAdminUserApi(id)
          navigate('/admin-users')
        },
      },
    })
  }

  useEffect(() => {
    document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('관리자 수정')
      getAdminUserApi(id).then((data) => {
        methods.setValue('name', data.name)
        methods.setValue('email', data.email)
        methods.setValue('role', data.roles[0]?.id)
        methods.setValue('customerId', data.customers[0]?.id)
        setSelectedRole(data.roles[0]?.id as RoleType)
        setSelectedCustomer(data.customers[0]?.id)
      })
    }
    return () => {
      setSelectedRole(RoleType.ADMIN)
      setSelectedCustomer(undefined)
    }
  }, [id, methods, title, setSelectedRole, setSelectedCustomer])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="관리자나 매니저를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/admin-users" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
    >
      {id && <SideLabel label="ID">{id}</SideLabel>}

      <InputSideLabelForm id="name" name="name" type="text" label="이름" />

      {!id && (
        <InputSideLabelForm
          id="password"
          name="password"
          type="password"
          label="비밀번호"
        />
      )}

      <InputSideLabelForm
        id="email"
        name="email"
        type="email"
        label="이메일"
        placeholder="example@everyoncorp.com"
      />

      <CustomerSelector />
    </ManageCreateLayout>
  )
}

export default AdminUserCreate
