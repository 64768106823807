import React, { useState } from 'react'
import ImageUploader from '../components/ImageUploader'
import { EditorFunctionProps, ImageExtensionProps } from '../types/editor.type'

type ImageProps = EditorFunctionProps & ImageExtensionProps

export default function Image({ editor, children, imageUploaded }: ImageProps) {
  const [open, setOpen] = useState<boolean>(false)
  const addImage = () => {
    // todo
    // 이미지 업로드, url로 입력할수 있는 모달 팝업 띄우기
    setOpen(true)
    // const url = window.prompt('URL')
    // if (url) {
    //   editor.chain().focus().setImage({ src: url }).run()
    // }
  }

  if (!editor) return null
  return (
    <>
      <button type="button" onClick={addImage}>
        {children}
      </button>
      <ImageUploader
        editor={editor}
        open={open}
        onClose={() => setOpen(false)}
        fileUpload={imageUploaded}
      />
    </>
  )
}
