import {
  BuildingOffice2Icon,
  CircleStackIcon,
  FolderIcon,
  HomeIcon,
  InformationCircleIcon,
  MapPinIcon,
  PhotoIcon,
  QrCodeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { atom, selector } from 'recoil'
import { loginUserSelector } from './login-user.recoil'
import { RoleType } from '../types/Role'

export const menuSelector = selector({
  key: `menuSelector`,
  get: () => [
    { name: '홈', href: '/', icon: HomeIcon, exact: true },
    {
      name: '관리자',
      href: '/admin-users',
      icon: UsersIcon,
      role: RoleType.ADMIN,
    },
    {
      name: '업체',
      href: '/customers',
      icon: BuildingOffice2Icon,
      role: RoleType.ADMIN,
    },
    { name: 'NFC', href: '/nfc', icon: QrCodeIcon, role: RoleType.ADMIN },
    {
      name: 'QR',
      href: '/qr',
      icon: QrCodeIcon,
      role: RoleType.ADMIN,
    },
    {
      name: '현장조사',
      href: '/survey-version',
      icon: CircleStackIcon,
      role: RoleType.ADMIN,
    },
    {
      name: '안내판',
      href: '/sign-board',
      icon: InformationCircleIcon,
    },
    { name: '장소', href: '/place', icon: MapPinIcon },
    {
      name: '문서',
      href: '/documents',
      icon: FolderIcon,
    },
    {
      name: '아이콘',
      href: '/place-icon',
      icon: PhotoIcon,
    },
  ],
})

export const filteredMenuSelector = selector({
  key: 'filteredMenuSelector',
  get: ({ get }) => {
    const user = get(loginUserSelector)
    const menu = get(menuSelector)
    const roles = user?.roles.map((role) => role.id)

    return menu.filter((m) => {
      if (!m.role) return true
      if (!roles) return false
      return roles.indexOf(m.role ?? '') > -1
    })
  },
})

export const currentRouteAtom = atom({
  key: `currentRouteAtom`,
  default: '/',
})

export const sideBarToggleAtom = atom({
  key: `sideBarToggleAtom`,
  default: false,
})
