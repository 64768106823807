import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Editor } from '@tiptap/react'
import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { getSource } from '../../../helpers/common.helper'
import { DefaultInputStyle } from '../../../styles/input.style'
import FileForm from '../../forms/FileForm'

type ImageUploaderProps = {
  editor: Editor
  contentId?: string
  open?: boolean
  onClose: () => void
  fileUpload?: (files: File[]) => Promise<void>
}

function ImageUploader({
  editor,
  contentId,
  open,
  onClose,
  fileUpload,
}: ImageUploaderProps) {
  // const fileUpload = useCallback(
  //   async (fileList: File[]) => {
  //     if (contentId) {
  //       const sb = await signBoardUploadApi(id, fileList)
  //       if (sb.files) setFiles(sb.files)
  //       showAlert('업로드 완료', {
  //         icon: <AlertCheckIcon />,
  //       })
  //     }
  //   },
  //   [id]
  // )

  const onDropCallback = async (files: File[]) => {
    files.forEach((file) => {
      editor
        .chain()
        .focus()
        .setImage({ src: getSource(file) })
        .run()
    })
    if (fileUpload) fileUpload(files)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-slate-900"
                    >
                      이미지 파일 선택
                    </Dialog.Title>
                    <div className="mt-2 flex flex-col gap-2">
                      <input
                        type="text"
                        className={DefaultInputStyle}
                        placeholder="이미지 주소를 넣어주세요."
                      />
                      <FileForm
                        id="files"
                        name="files"
                        onDropCallback={async (files: File[]) => {
                          onDropCallback(files)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col items-end">
                  <button
                    type="button"
                    className="inline-flex justify-end rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    닫기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ImageUploader
