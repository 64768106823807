import Joi from 'joi'
import validator from '../lib/validate'

export default {}

export const QrCreateValidation = validator().object({
  customerId: Joi.optional(),
  placeId: Joi.optional(),
  documentId: Joi.optional(),
  link: Joi.optional(),
  title: Joi.optional(),
  desc: Joi.optional(),
})
