import React from 'react'
import { EditorFunctionProps } from '../types/editor.type'

export default function Youtube({ editor, children }: EditorFunctionProps) {
  if (!editor) return null
  const addYoutubeVideo = () => {
    const url = prompt('Enter YouTube URL')

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
      })
    }
  }
  return (
    <button type="button" onClick={addYoutubeVideo}>
      {children}
    </button>
  )
}
