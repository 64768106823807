import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getNfcListApi } from '../api/nfc.api'
import SearchInput from '../components/inputs/SearchInput'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import Select from '../components/select/Select'
import { CustomerSelector } from '../components/survey/ListFilters'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import { customerAtom } from '../recoil/customer.recoil'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TbodyStyle,
  TdEndStyle,
  TdStartStyle,
  TdStyle,
  TheadStyle,
  ThEndStyle,
  ThStartStyle,
  ThStyle,
} from '../styles/table.style'
import { Nfc, NfcContents, NfcContentTypeEnum } from '../types/Nfc'

function NfcList(): JSX.Element {
  const [nfcList, setNfcList] = useState<Nfc[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const customer = useRecoilValue(customerAtom)

  const getNfcList = useCallback(
    async (searchField?: string, keyword?: string) => {
      getNfcListApi({ page, limit, searchField, keyword }, customer?.id).then(
        (data) => {
          setNfcList(data.items)
          setTotal(data.total)
        }
      )
    },
    [page, customer, limit]
  )
  const handleOnChangePage = (p: number) => setPage(p)
  const handleOnSearch = (searchField?: string, keyword?: string) => {
    getNfcList('nameOrCode', keyword)
  }

  const renderContents = (contents?: NfcContents[]) => {
    if (!contents) return '컨텐츠 없음'
    if (!contents.length) return '컨텐츠 없음'
    return contents.map((item) => {
      switch (item.type) {
        case NfcContentTypeEnum.DOCUMENT:
          return <span key={item.contentId}>[문서] {item.document?.title}</span>
        case NfcContentTypeEnum.PLACE:
          return <span key={item.contentId}>[장소] {item.place?.title}</span>
        case NfcContentTypeEnum.LINK:
          return <span key={item.contentId}>[링크] {item.contents}</span>
        case NfcContentTypeEnum.SIGNBOARD:
          return (
            <span key={item.contentId}>
              [안내판] {item.signBoard?.placeTitle}
            </span>
          )
        default:
          return '컨텐츠 없음'
      }
    })
  }

  useEffect(() => {
    document.title = `NFC 관리 - ${ADMIN_TITLE}`
    getNfcList()
  }, [getNfcList])
  return (
    <ManageListLayout
      title="NFC 관리"
      description="NFC를 관리하는 페이지 입니다."
      createButtonChildren={
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
          <Link to="/nfc/bulk-create" className={PrimaryButtonStyle}>
            Bulk add NFC
          </Link>
          <Link to="/nfc/create" className={PrimaryButtonStyle}>
            NFC 생성하기
          </Link>
        </div>
      }
      searchChildren={
        <SearchInput className="w-30" onSearch={handleOnSearch} />
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
    >
      <div className="flex flex-col sm:flex-col sm:justify-between sm:items-end md:flex-row gap-2 pb-2">
        <div className="flex flex-row gap-2">
          <CustomerSelector />
          <Select
            label="페이지당 건수"
            options={['5', '10', '20', '50', '100'].map((item) => ({
              text: item,
              value: item,
            }))}
            defaultValue={20}
            onChange={(e) => {
              setLimit(Number(e.target.value))
              setPage(1)
            }}
          />
        </div>
        <div className="text-slate-700">전체 수: {total}</div>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    아이디
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    컨텐츠
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {nfcList.length === 0 && (
                  <tr>
                    <td colSpan={3} className={classNames(TdStartStyle)}>
                      데이터가 없습니다.
                    </td>
                  </tr>
                )}
                {nfcList.map((nfc, i: number) => (
                  <tr key={nfc.id}>
                    <td className={classNames(TdStartStyle)}>
                      {(page - 1) * limit + i + 1}
                    </td>
                    <td className={classNames(TdStyle)}>{nfc.id}</td>
                    <td className={classNames(TdStyle)}>
                      {renderContents(nfc.contents)}
                    </td>
                    <td
                      className={classNames(
                        TdEndStyle,
                        'flex justify-end space-x-4'
                      )}
                    >
                      <Link
                        to={`/nfc/view/${nfc.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        상세<span className="sr-only">, {nfc.name}</span>
                      </Link>
                      <Link
                        to={`/nfc/edit/${nfc.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        수정<span className="sr-only">, {nfc.name}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default NfcList
