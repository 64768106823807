/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  customersSelector,
  selectedCustomerAtom,
  selectedRoleAtom,
} from '../../recoil/user-create.recoil'
import { RoleType } from '../../types/Role'
import SelectSideLabelForm from '../forms/SelectSideLabel'

const roles = [
  { value: 'admin', text: '관리자' },
  { value: 'manager', text: '업체 매니저' },
]

function CustomerSelector() {
  const { setValue } = useFormContext()
  const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleAtom)
  const customers = useRecoilValue(customersSelector)
  const selectedCustomer = useRecoilValue(selectedCustomerAtom)

  const handleChangeType = useCallback(
    (value: string) => {
      setSelectedRole(value as RoleType)
      if (value === 'admin') {
        setValue('customerId', undefined)
      } else {
        setValue('customerId', selectedCustomer)
      }
    },
    [setSelectedRole, setValue, selectedCustomer]
  )

  useEffect(() => {
    handleChangeType(selectedRole)
  }, [handleChangeType, selectedRole])

  if (!customers) return <></>

  return (
    <>
      <SelectSideLabelForm
        id="role"
        name="role"
        label="타입"
        required
        options={roles}
        onChange={(e) => {
          handleChangeType(e.target.value)
        }}
      />
      {selectedRole !== RoleType.ADMIN && (
        <SelectSideLabelForm
          id="customerId"
          name="customerId"
          label="업체"
          required
          options={customers}
        />
      )}
    </>
  )
}

export default CustomerSelector
