import React, { SelectHTMLAttributes, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import useSurvey from '../../hooks/survey.hook'
import Select2Form from '../forms/Select2'
import SideLabel from '../forms/SideLabel'

type CustomerSelectorSideLabelFormProps =
  SelectHTMLAttributes<HTMLSelectElement>
export default function CustomerSelectorSideLabelForm({
  required,
  disabled,
}: CustomerSelectorSideLabelFormProps) {
  const methods = useFormContext()
  const { customer, setCustomer, customers } = useSurvey()
  useEffect(() => {
    if (!customer) {
      setCustomer(customers[0])
      if (customers[0]) {
        methods.setValue('customerId', customers[0].id)
      }
    } else {
      methods.setValue('customerId', customer.id)
    }
  }, [customer, customers, setCustomer, methods])

  return (
    <SideLabel label="업체 선택" id="customerId" required={required}>
      <Select2Form
        name="customerId"
        options={customers.map((item) => ({
          text: item.name,
          value: `${item.id}`,
        }))}
        onChange={(e) =>
          setCustomer(customers.find((item) => `${item.id}` === e.target.value))
        }
        disabled={disabled}
        required={required}
      />
    </SideLabel>
  )
}
