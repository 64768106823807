import React, { useEffect } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import { ADMIN_TITLE } from '../constants/title'
import { LoginRequest } from '../types/api/login'
import { LoginValidation } from '../validations/login.validation'
import LogoIcon from '../components/icons/logo.icon'
import useAuth from '../hooks/auth.hook'

function Login() {
  const navigate = useNavigate()
  const formMethod = useForm({
    resolver: joiResolver(LoginValidation),
  })
  const { loginUser, login } = useAuth()

  const handleLogin: SubmitHandler<FieldValues> = async (formData) => {
    await login(formData as LoginRequest)
    navigate('/')
  }

  useEffect(() => {
    document.title = `로그인 - ${ADMIN_TITLE}`
  })

  if (loginUser) {
    return <Navigate to="/" replace />
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
        <LogoIcon className="w-20" />
        <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-slate-900">
          로그인
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            className="space-y-6"
            methods={formMethod}
            onSubmit={handleLogin}
          >
            <Input
              type="email"
              id="email"
              name="email"
              label="이메일"
              placeholder="example@everyoncorp.com"
            />

            <Input
              type="password"
              id="password"
              name="password"
              label="비밀번호"
            />

            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-slate-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-slate-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-teal-600 hover:text-teal-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div> */}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                로그인
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
