import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getPlaceChildrenApi } from '../api/place.api'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import PlaceItem from '../components/place/PlaceItem'
import Select from '../components/select/Select'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import { customerAtom } from '../recoil/customer.recoil'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TheadStyle,
  ThStartStyle,
  ThEndStyle,
  TbodyStyle,
  ThStyle,
} from '../styles/table.style'
import { Place } from '../types/Place'

function PlaceChildren() {
  const customer = useRecoilValue(customerAtom)
  const [place, setPlace] = useState<Place>()
  const { id } = useParams()
  const [total, setTotal] = useState(0)

  const childrenSort = (a: Place, b: Place) => {
    const aTitle = Number(a.title.replace('층', '')) || a.title
    const bTitle = Number(b.title.replace('층', '')) || b.title
    return aTitle > bTitle ? 1 : -1
  }

  useEffect(() => {
    document.title = `장소 관리 - ${ADMIN_TITLE}`
    if (id) {
      getPlaceChildrenApi(id).then((data) => {
        setPlace(data)
        setTotal(data.children?.length || 0)
      })
    }
  }, [id])
  return (
    <ManageListLayout
      title="장소 관리"
      description="장소를 관리하는 페이지 입니다."
      createButtonChildren={
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
          <Link to={`/place/create/${id}`} className={PrimaryButtonStyle}>
            장소 생성
          </Link>
        </div>
      }
    >
      <div className="flex flex-col justify-start gap-2 sm:justify-between sm:items-end  pb-2">
        업체: {customer?.name}
        <div className="text-slate-700">전체 수: {total + 1}</div>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    장소명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    사용여부
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {!place?.parent && (
                  <PlaceItem
                    id={place?.id}
                    nextLink="/place"
                    linkLabel="상위 장소 보기"
                    place={place}
                    num={1}
                  >
                    {place?.title}
                  </PlaceItem>
                )}
                {place?.parent && (
                  <PlaceItem
                    id={place.id}
                    nextLink={`/place/children/${place.parent.id}`}
                    linkLabel="상위 장소 보기"
                    place={place}
                    num={1}
                  >
                    {place.title}
                  </PlaceItem>
                )}
                {(!place?.children || place.children.length === 0) && (
                  <tr>
                    <td colSpan={3} className={classNames(ThStartStyle)}>
                      등록된 데이터가 없습니다
                    </td>
                  </tr>
                )}
                {place?.children
                  ?.sort(childrenSort)
                  .map((item, num: number) => (
                    <PlaceItem
                      key={item.id}
                      id={item.id}
                      nextLink={`/place/children/${item.id}`}
                      linkLabel="하위 장소 보기"
                      place={item}
                      num={num + 2}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp; ㄴ{item.title}
                    </PlaceItem>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default PlaceChildren
