import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  createDocumentApi,
  deleteDocumentApi,
  getDocumentApi,
  updateDocumentApi,
} from '../api/document.api'
import { ADMIN_TITLE } from '../constants/title'
import {
  CreateDocumentRequest,
  UpdateDocumentRequest,
} from '../types/api/document'
import Button from '../components/button/Button'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { customerAtom } from '../recoil/customer.recoil'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import { DocumentModel, DocumentState, DocumentType } from '../types/Document'
import SideLabel from '../components/forms/SideLabel'
import CheckBoxForm from '../components/forms/CheckBox'
import { Qr } from '../types/Qr'
import Editor from '../components/editors/Editor'
import { DocumentCreateValidation } from '../validations/document.validation'

function DocumentCreate(): JSX.Element {
  const methods = useForm({
    resolver: joiResolver(DocumentCreateValidation),
  })
  const navigate = useNavigate()
  const customer = useRecoilValue(customerAtom)
  const { id } = useParams()
  const [doc, setDoc] = useState<DocumentModel>()

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updateDocumentApi(id, formData as UpdateDocumentRequest)
    } else {
      await createDocumentApi(formData as CreateDocumentRequest)
    }
    navigate('/documents')
  }

  const handleDeleteButton = async () => {
    if (id) await deleteDocumentApi(id)
    navigate('/documents')
  }

  useEffect(() => {
    document.title = `${ADMIN_TITLE} - 문서`
    if (id) {
      getDocumentApi(id).then((data) => {
        setDoc(data)
        methods.setValue('customerId', data.customer.id)
        methods.setValue('title', data.title)
        methods.setValue('contents', data.contents)
      })
    } else {
      methods.setValue('customerId', customer?.id)
    }
    methods.setValue('type', DocumentType.Page)
  }, [id, methods, customer, navigate])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title="문서 수정"
      description="문서를 관리하는 페이지 입니다."
      className="py-6 px-4 space-y-8 divide-y divide-slate-200 bg-slate-100"
      cancelButton={
        <Link to="/documents" className={DefaultButtonStyle}>
          목록
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
    >
      {id && <SideLabel label="ID">{id}</SideLabel>}
      {doc && doc?.state !== DocumentState.Temp && (
        <SideLabel label="상태">
          <select>
            <option value="10">미사용</option>
            <option value="20">사용</option>
          </select>
        </SideLabel>
      )}
      <CustomerSelectorSideLabelForm />
      <InputSideLabelForm id="title" name="title" type="text" label="제목" />
      <SideLabel label="내용">
        <Editor name="contents" content={doc?.contents} />
      </SideLabel>
    </ManageCreateLayout>
  )
}

export default DocumentCreate
