import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import React, {
  FormEvent,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useFormContext } from 'react-hook-form'
import { fileListToArray } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

type FileProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  name: string
  children?: ReactNode
  onDropCallback?: (files: File[]) => Promise<void>
  showItem?: boolean
}
function FileForm({
  name,
  id,
  children,
  disabled,
  required,
  onDropCallback,
  showItem,
}: FileProps) {
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const [droppedFiles, setDroppedFiles] = useState<File[]>([])
  const onDrop = useCallback(
    (item: HTMLInputElement) => {
      if (item) {
        const { files } = item
        if (!files) return
        const newFiles = fileListToArray(files)
        setDroppedFiles(newFiles)
        formMethods.setValue(name, newFiles)
        if (onDropCallback) {
          onDropCallback(newFiles)
        }
      }
    },
    [formMethods, setDroppedFiles, name, onDropCallback]
  )
  const [, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: HTMLInputElement) {
        if (onDrop) {
          onDrop(item)
        }
      },
      // canDrop(item: any) {
      //   // console.log('canDrop', item.files, item.items)
      //   return true
      // },
      // hover(item: any) {
      //   // console.log('hover', item.files, item.items)
      // },
      // collect: (monitor: DropTargetMonitor) => {
      //   // const item = monitor.getItem() as any
      //   // return {
      //   //   isOver: monitor.isOver(),
      //   //   canDrop: monitor.canDrop(),
      //   // }
      // },
    }),
    [onDrop]
  )
  return (
    <div className="relative mt-1 sm:col-span-2 sm:mt-0 space-y-1">
      <div className="max-w-xl">
        <label
          ref={drop}
          className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-slate-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-teal-400 focus:outline-none"
        >
          <span className="flex items-center space-x-2">
            <ArrowUpTrayIcon className="w-6 h-6 text-slate-600" />
            <span className="font-medium text-slate-600">
              파일을 드래그하여 업로드 하거나 &nbsp;
              <span className="text-teal-600 underline">여기</span>를 누르세요.
            </span>
          </span>
          <input
            id={id}
            required={false}
            disabled={disabled}
            type="file"
            className="hidden"
            {...formMethods.register(name)}
            onChange={(event: FormEvent<HTMLInputElement>) =>
              onDrop(event.currentTarget)
            }
          />
        </label>
      </div>
      {children}
      <ErrorMessage error={error} name={name} />
      {showItem && droppedFiles.length > 0 && (
        <div>
          {droppedFiles.map((item) => (
            <span key={`item-${Date.now()}`}>{item.name}</span>
          ))}
        </div>
      )}
    </div>
  )
}

export default FileForm
