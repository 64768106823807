import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../components/layout/Layout'
import Home from '../pages/Home'
import AdminUserList from '../pages/AdminUserList'
import Login from '../pages/Login'
import PrivateRouter from './privateRouter'
import NotFound from '../pages/NotFound'
import NoneLayout from '../components/layout/NoneLayout'
import AdminUserCreate from '../pages/AdminUserCreate'
import CustomerList from '../pages/CustomerList'
import CustomerCreate from '../pages/CustomerCreate'
import DocumentList from '../pages/DocumentList'
import DocumentCreate from '../pages/DocumentCreate'
import NfcList from '../pages/NfcList'
import NfcCreate from '../pages/NfcCreate'
import NfcBulkCreate from '../pages/NfcBulkCreate'
import Profile from '../pages/Profile'
import PlaceList from '../pages/PlaceList'
import PlaceChildren from '../pages/PlaceChildren'
import PlaceCreate from '../pages/PlaceCreate'
import SurveyList from '../pages/SurveyList'
import SurveyCreate from '../pages/SurveyCreate'
import SurveyBulkCreate from '../pages/SurveyBulkCreate'
import SurveyEdit from '../pages/SurveyEdit'
import SignBoardList from '../pages/SignBoardList'
import SignBoardCreate from '../pages/SignBoardCreate'
import SurveyVersionList from '../pages/SurveyVersionList'
import SurveyVersionCreate from '../pages/SurveyVersionCreate'
import SurveyCreate2 from '../pages/SurveyCreate2'
import PlaceIconList from '../pages/PlaceIconList'
import PlaceIconCreate from '../pages/PlaceIconCreate'
import AdminUserView from '../pages/AdminUserView'
import CustomerView from '../pages/CustomerView'
import NfcView from '../pages/NfcView'
import SurveyVersionView from '../pages/SurveyVersionView'
import SurveyView from '../pages/SurveyView'
import SignBoardView from '../pages/SignBoardView'
import SurveyBulkCreate2 from '../pages/SurveyBulkCreate2'
import QrList from '../pages/QrList'
import QrCreate from '../pages/QrCreate'
import QrView from '../pages/QrView'
import DocumentTemp from '../pages/DocumentTemp'

export default function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRouter />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/admin-users">
              <Route index element={<AdminUserList />} />
              <Route path="/admin-users/create" element={<AdminUserCreate />} />
              <Route
                path="/admin-users/edit/:id"
                element={<AdminUserCreate />}
              />
              <Route path="/admin-users/view/:id" element={<AdminUserView />} />
            </Route>
            <Route path="/customers">
              <Route index element={<CustomerList />} />
              <Route path="/customers/create" element={<CustomerCreate />} />
              <Route path="/customers/edit/:id" element={<CustomerCreate />} />
              <Route path="/customers/view/:id" element={<CustomerView />} />
            </Route>
            <Route path="/documents">
              <Route index element={<DocumentList />} />
              <Route path="create" element={<DocumentCreate />} />
              <Route path="temp/:id" element={<DocumentTemp />} />
              <Route path="edit/:id" element={<DocumentCreate />} />
            </Route>
            <Route path="/nfc">
              <Route index element={<NfcList />} />
              <Route path="/nfc/bulk-create" element={<NfcBulkCreate />} />
              <Route path="/nfc/create" element={<NfcCreate />} />
              <Route path="/nfc/edit/:id" element={<NfcCreate />} />
              <Route path="/nfc/view/:id" element={<NfcView />} />
            </Route>
            <Route path="/profile">
              <Route index element={<Profile />} />
            </Route>
            <Route path="/place">
              <Route index element={<PlaceList />} />
              <Route path="/place/children/:id" element={<PlaceChildren />} />
              <Route path="/place/create" element={<PlaceCreate />} />
              <Route path="/place/create/:parentId" element={<PlaceCreate />} />
              <Route path="/place/edit/:id" element={<PlaceCreate />} />
            </Route>
            <Route path="survey-version">
              <Route index element={<SurveyVersionList />} />
              <Route path="create" element={<SurveyVersionCreate />} />
              <Route path="edit/:id" element={<SurveyVersionCreate />} />
              <Route path="view/:id" element={<SurveyVersionView />} />
            </Route>
            <Route path="survey">
              <Route index element={<SurveyList />} />
              <Route path="create" element={<SurveyCreate2 />} />
              <Route path="edit/:id" element={<SurveyCreate2 />} />
              <Route path="view/:id" element={<SurveyView />} />
              <Route path="bulk-create" element={<SurveyBulkCreate2 />} />
            </Route>
            <Route path="sign-board">
              <Route index element={<SignBoardList />} />
              <Route path="create" element={<SignBoardCreate />} />
              <Route path="edit/:id" element={<SignBoardCreate />} />
              <Route path="view/:id" element={<SignBoardView />} />
            </Route>
            <Route path="place-icon">
              <Route index element={<PlaceIconList />} />
              <Route path="create" element={<PlaceIconCreate />} />
              <Route path="edit/:id" element={<PlaceIconCreate />} />
            </Route>
            <Route path="qr">
              <Route index element={<QrList />} />
              <Route path="create" element={<QrCreate />} />
              <Route path="edit/:id" element={<QrCreate />} />
              <Route path="view/:id" element={<QrView />} />
            </Route>
          </Route>
        </Route>
        <Route element={<NoneLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
