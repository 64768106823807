import React, { HTMLAttributes } from 'react'

type ViewLayoutProps = HTMLAttributes<HTMLElement> & {
  title: string
  description?: string
  children?: React.ReactNode
  cancelButton?: React.ReactNode
  editButton?: React.ReactNode
  delButton?: React.ReactNode
}
function ViewLayout({
  title,
  description,
  children,
  cancelButton,
  editButton,
  delButton,
}: ViewLayoutProps) {
  return (
    <div className="py-6 px-4 sm:px-6 lg:px-8 space-y-8 divide-y divide-slate-200">
      <div className="space-y-6 sm:space-y-5">
        <div>
          <h3 className="text-lg font-medium leading-6 text-slate-900">
            {title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-slate-500">{description}</p>
        </div>
        {children}
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          {cancelButton}
          <div className="flex-1 flex justify-end gap-2">
            {delButton}
            {editButton}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ViewLayout
