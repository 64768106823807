import React from 'react'
import { EditorFunctionProps } from '../types/editor.type'

export default function Link({ editor, children }: EditorFunctionProps) {
  if (!editor) return null
  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }
  return (
    <>
      <button type="button" onClick={setLink}>
        setLink
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
      >
        unsetLink
      </button>
    </>
  )
}
