import { joiResolver } from '@hookform/resolvers/joi'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createQrApi, deleteQrApi, getQrApi, updateQrApi } from '../api/qr.api'
import Button from '../components/button/Button'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import { FinderItem } from '../components/finders/Finder'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import SideLabel from '../components/forms/SideLabel'
import TextAreaSideLabelForm from '../components/forms/TextareaSideLabel'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import QrContentForm from '../components/qr/QrContentForm'
import { ADMIN_TITLE } from '../constants/title'
import useAuth from '../hooks/auth.hook'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { CreateQrRequest, UpdateQrRequest } from '../types/api/qr'
import { QrCreateValidation } from '../validations/qr.validation'

function AdminQrCreate() {
  const methods = useForm({
    resolver: joiResolver(QrCreateValidation),
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const [title, setTitle] = useState('QR 생성')
  const [document, setDocument] = useState<FinderItem>()
  const [place, setPlace] = useState<FinderItem>()
  const { isAdmin } = useAuth()
  const [contentType, setContentType] = useState<string>()

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { code, name, customerId, documentId, placeId } = formData
    const data = {
      id: formData.id,
      code,
      name,
      customerId,
      documentId: documentId || undefined,
      placeId: placeId || undefined,
      link: formData.link || undefined,
      title: formData.title || undefined,
      desc: formData.desc || undefined,
    }
    if (id) {
      await updateQrApi(id, data as UpdateQrRequest)
    } else {
      await createQrApi(data as CreateQrRequest)
    }
    navigate('/qr')
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteQrApi(id)
          navigate('/qr')
        },
      },
    })
  }

  const loadData = useCallback(async () => {
    if (id) {
      await getQrApi(id).then((data) => {
        methods.setValue('customerId', data.customerId)
        methods.setValue('placeId', data.place?.id)
        methods.setValue('documentId', data.document?.id)
        methods.setValue('link', data.link)
        methods.setValue('title', data.title)
        methods.setValue('desc', data.desc)
        if (data.place) {
          setPlace({ text: data.place.title, value: data.place.id })
          setContentType('place')
        }
        if (data.document) {
          setDocument({ text: data.document.title, value: data.document.id })
          setContentType('document')
        }
        if (data.link) {
          setContentType('link')
        }
      })
    }
  }, [id, methods])

  useEffect(() => {
    window.document.title = `${title} - ${ADMIN_TITLE}`
    if (id) {
      setTitle('QR 수정')
    }
    loadData()
  }, [loadData, id, title])

  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="QR을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/qr" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      {id && (
        <SideLabel required label="ID">
          {id}
        </SideLabel>
      )}

      {isAdmin && <CustomerSelectorSideLabelForm required />}
      <QrContentForm
        required
        document={document}
        place={place}
        contentType={contentType}
      />
      <InputSideLabelForm type="text" name="title" id="title" label="제목" />
      <TextAreaSideLabelForm id="desc" name="desc" label="설명" />
    </ManageCreateLayout>
  )
}

export default AdminQrCreate
