import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { text } from 'stream/consumers'
import { getPlaceListApi } from '../api/place.api'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import PlaceItem from '../components/place/PlaceItem'
import Select from '../components/select/Select'
import { CustomerSelector } from '../components/survey/ListFilters'
import { ADMIN_TITLE } from '../constants/title'
import { classNames } from '../helpers/common.helper'
import useAuth from '../hooks/auth.hook'
import { customerAtom } from '../recoil/customer.recoil'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TheadStyle,
  ThStartStyle,
  ThEndStyle,
  TbodyStyle,
  ThStyle,
  TdStartStyle,
  TdStyle,
} from '../styles/table.style'
import { Place } from '../types/Place'

function PlaceList() {
  const customer = useRecoilValue(customerAtom)
  const [placeList, setPlaceList] = useState<Place[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const { isAdmin } = useAuth()

  const handleOnChangePage = (p: number) => setPage(p)
  const getPlaceList = useCallback(
    async (searchField?: string, keyword?: string) => {
      getPlaceListApi(page, limit, customer?.id).then((data) => {
        setPlaceList(data.items)
        setTotal(data.total)
      })
    },
    [page, customer, limit]
  )

  useEffect(() => {
    document.title = `장소 관리 - ${ADMIN_TITLE}`
    getPlaceList()
  }, [getPlaceList])
  return (
    <ManageListLayout
      title="장소 관리"
      description="장소를 관리하는 페이지 입니다."
      createButtonChildren={
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
          <Link to="/place/create" className={PrimaryButtonStyle}>
            장소 생성
          </Link>
        </div>
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
    >
      <div className="flex flex-col sm:flex-col sm:justify-between sm:items-end md:flex-row gap-2 pb-2">
        <div className="flex flex-row gap-2">
          {isAdmin && <CustomerSelector />}
          <Select
            label="페이지당 건수"
            options={['5', '10', '20', '50', '100'].map((item) => ({
              text: item,
              value: item,
            }))}
            defaultValue={20}
            onChange={(e) => {
              setLimit(Number(e.target.value))
              setPage(1)
            }}
          />
        </div>

        <div className="text-slate-700">전체 수: {total}</div>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    장소명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    업체
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    사용여부
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {placeList.length === 0 && (
                  <tr>
                    <td colSpan={4} className={classNames(TdStartStyle)}>
                      등록된 데이터가 없습니다
                    </td>
                  </tr>
                )}
                {placeList.map((place, num: number) => (
                  <PlaceItem
                    key={place.id}
                    id={place.id}
                    nextLink={`/place/children/${place.id}`}
                    linkLabel="하위 장소 보기"
                    place={place}
                    showCustomer
                    num={num + 1}
                  >
                    {place.title}
                  </PlaceItem>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default PlaceList
