export default {}

export const TableStyle = 'min-w-full divide-y divide-slate-300'

export const TheadStyle = 'bg-slate-50'

export const ThStyle =
  'px-3 py-3.5 text-left text-sm font-semibold text-slate-900'

export const ThStartStyle =
  'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-6'

export const TbodyStyle = 'divide-y divide-slate-200 bg-white'

export const ThEndStyle = 'relative py-3.5 pl-3 pr-4 sm:pr-6'

export const TdStyle = 'px-3 py-3.5 text-sm text-slate-900 whitespace-nowrap'

export const TdStartStyle =
  'py-3.5 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-6 whitespace-nowrap'

export const TdEndStyle =
  'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium text-slate-900 sm:pr-6 whitespace-nowrap'
