import Joi from 'joi'
import validator from '../lib/validate'

export const UserCreateValidation = validator().object({
  name: Joi.string().required(),
  password: Joi.string().required().min(8),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } }),
  customerId: Joi.optional(),
  role: Joi.string().required(),
})

export const UserUpdateValidation = validator().object({
  name: Joi.string().required(),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } }),
  customerId: Joi.optional(),
  role: Joi.string().required(),
})

export default { UserCreateValidation }
