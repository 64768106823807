import React, { HTMLAttributes } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../helpers/common.helper'

type SimpleAlertProps = HTMLAttributes<HTMLElement>

export default function SimpleAlert({ children, className }: SimpleAlertProps) {
  return (
    <div className={classNames('rounded-md bg-yellow-50 p-4', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">중요</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
