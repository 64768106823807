import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Bars4Icon, UserCircleIcon } from '@heroicons/react/24/outline'
import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../helpers/common.helper'
import { sideBarToggleAtom } from '../../recoil/navigation.recoil'
import { CustomerSelector } from '../survey/ListFilters'
import useAuth from '../../hooks/auth.hook'

type ProfileMenuItem = {
  type: string
  name: string
  href?: string
  onClick?: () => void
}

export default function Header(): JSX.Element {
  const setSidebarOpen = useSetRecoilState(sideBarToggleAtom)
  const { loginUser, isAdmin, logout } = useAuth()
  const navigate = useNavigate()
  const signOut = () => {
    logout()
    navigate('/login')
  }
  const userNavigation: ProfileMenuItem[] = [
    // { type: 'link', name: 'Your Profile', href: '/profile' },
    { type: 'button', name: '로그아웃', onClick: signOut },
  ]

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        type="button"
        className="px-4 border-r border-slate-200 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">사이드바 열기</span>
        <Bars4Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          {/* <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              검색
            </label>
            <div className="relative w-full text-slate-400 focus-within:text-slate-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-slate-900 placeholder-slate-500 focus:outline-none focus:placeholder-slate-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="검색"
                type="search"
                name="search"
              />
            </div>
          </form> */}
        </div>
        {!isAdmin && (
          <div className="ml-4 flex items-center md:ml-6">
            <CustomerSelector hideLabel />
          </div>
        )}
        <div className="ml-4 flex items-center md:ml-6">
          {/* <button
            type="button"
            className="bg-white p-1 rounded-full text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
          >
            <span className="sr-only">알림 보기</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button> */}

          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 space-x-2">
                <span className="sr-only">유저 메뉴 열기</span>
                <UserCircleIcon className="h-8 w-8 rounded-full text-slate-400" />
                <span className="px-2">{loginUser?.name}</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => {
                  if (item.type === 'button') {
                    return (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={item?.onClick}
                            className={classNames(
                              active ? 'bg-slate-100' : '',
                              'block px-4 py-2 text-sm text-slate-700'
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    )
                  }
                  return (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={item?.onClick}
                          href={item?.href}
                          className={classNames(
                            active ? 'bg-slate-100' : '',
                            'block px-4 py-2 text-sm text-slate-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  )
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}
