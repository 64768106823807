import { selector, useRecoilRefresher_UNSTABLE } from 'recoil'
import { getPlaceListApi } from '../api/place.api'
import { Place } from '../types/Place'
import { customerAtom } from './customer.recoil'

// base place list
export const basePlaceListSelector = selector<Place[]>({
  key: 'basePlaceListSelector',
  get: ({ get }) => {
    const customer = get(customerAtom)
    if (!customer) return []
    return getPlaceListApi(1, 1000, customer.id).then((data) => data.items)
  },
})

export function usePlaceRefresh() {
  const placeRefresh = useRecoilRefresher_UNSTABLE(basePlaceListSelector)
  const refresh = () => {
    placeRefresh()
  }
  return refresh
}

export default {}
