import { joiResolver } from '@hookform/resolvers/joi'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import React, { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteNfcApi, getNfcApi } from '../api/nfc.api'
import Button from '../components/button/Button'
import { FinderItem } from '../components/finders/Finder'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import { nfcTypeToText } from '../helpers/nfc.helper'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { Nfc, NfcContentTypeEnum } from '../types/Nfc'
import { NfcCreateValidation } from '../validations/nfc.validation'

function NfcView(): JSX.Element {
  const { id } = useParams()
  const [nfc, setNfc] = useState<Nfc>()
  const navigate = useNavigate()
  const methods = useForm({
    resolver: joiResolver(NfcCreateValidation),
  })
  const [contentType, setContentType] = useState<NfcContentTypeEnum>()
  const [document, setDocument] = useState<FinderItem>()
  const [place, setPlace] = useState<FinderItem>()
  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteNfcApi(id)
          navigate('/nfc')
        },
      },
    })
  }
  const loadData = useCallback(async () => {
    if (id) {
      await getNfcApi(id).then((data) => {
        methods.setValue('id', data.id)
        methods.setValue('name', data.name)
        methods.setValue('customerId', data.customer?.id)
        if (data.contents.length > 0) {
          const { type } = data.contents[0]
          methods.setValue('type', type)
          setContentType(type)
          switch (type) {
            case 'document':
              if (data.contents[0].document)
                setDocument({
                  text: data.contents[0].document?.title,
                  value: data.contents[0]?.id,
                })
              break
            case 'place':
              if (data.contents[0].place)
                setPlace({
                  text: data.contents[0].place?.title,
                  value: data.contents[0].place?.id,
                })
              break
            case 'link':
            default:
              methods.setValue('contents', data.contents[0].contents)
              break
          }
        }
      })
    }
  }, [id, methods])

  useEffect(() => {
    if (id) {
      getNfcApi(id).then((data) => {
        setNfc(data)
      })
      loadData()
    }
  }, [loadData, id])

  return (
    <ViewLayout
      title="NFC 상세"
      description="NFC를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/nfc" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/nfc/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="아이디">{nfc?.id}</SideLabel>
      <SideLabel label="업체 선택">{nfc?.customer?.name}</SideLabel>
      <SideLabel label="컨텐츠 종류">{nfcTypeToText(contentType)}</SideLabel>

      {contentType === 'document' && (
        <SideLabel label="문서 선택">
          {nfc?.contents[0].document?.title}
        </SideLabel>
      )}
      {contentType === 'place' && (
        <SideLabel label="장소">{nfc?.contents[0].place?.title}</SideLabel>
      )}
      {contentType === 'link' && (
        <SideLabel label="링크">{nfc?.contents[0].contents}</SideLabel>
      )}
    </ViewLayout>
  )
}
export default NfcView
