import React, { SelectHTMLAttributes, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import useSurvey from '../../hooks/survey.hook'
import Select2Form from '../forms/Select2'
import SideLabel from '../forms/SideLabel'

type SurveyVersionSelectorFormProps = SelectHTMLAttributes<HTMLSelectElement>

function SurveyVersionSelectorForm({
  disabled,
  required,
}: SurveyVersionSelectorFormProps) {
  const methods = useFormContext()
  const { versions, version, setVersion } = useSurvey()
  const filteredVersions = versions.filter((item) => !item.publishedAt)
  useEffect(() => {
    if (!version) {
      setVersion(filteredVersions[0])
      if (filteredVersions[0]) {
        methods.setValue('surveyVersionId', `${filteredVersions[0].id}`)
      }
    } else {
      methods.setValue('surveyVersionId', `${version.id}`)
    }
  }, [version, setVersion, filteredVersions, methods])
  return (
    <SideLabel label="버전 선택" id="surveyVersionId" required={required}>
      <Select2Form
        name="surveyVersionId"
        options={filteredVersions.map((item) => ({
          text: item.name,
          value: `${item.id}`,
        }))}
        onChange={(e) =>
          setVersion(versions.find((item) => `${item.id}` === e.target.value))
        }
        disabled={disabled}
        required={required}
      />
    </SideLabel>
  )
}

export default SurveyVersionSelectorForm
