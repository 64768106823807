import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline'
import React, {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { classNames } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

export type Finder2Item = {
  text: string
  value: string
}

type Finder2Props = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string
  name: string
  onSearch?: (keyword: string) => Promise<Finder2Item[]>
  defaultItem?: Finder2Item
}

function Finder2({
  label,
  name,
  onSearch,
  defaultItem,
  className,
  disabled,
  required,
}: Finder2Props) {
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const [selectedItem, setSelectedItem] = useState<Finder2Item>()
  const [items, setItems] = useState<Finder2Item[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredItems = keyword
    ? items?.filter((item) => {
        if (keyword.length === 0) return true
        return item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1
      })
    : items

  const handleSelectItem = (item: Finder2Item): void => {
    setSelectedItem(item)
    formMethods.setValue(name, item.value)
    if (inputRef && inputRef.current) inputRef.current.value = item.text
    setIsOpen(false)
  }
  const handleSearch = useCallback(
    async (query: string): Promise<void> => {
      if (onSearch) {
        const data = await onSearch(query)
        setItems(data)
        if (defaultItem) {
          setSelectedItem(defaultItem)
          formMethods.setValue(name, defaultItem.value)
          if (inputRef && inputRef.current)
            inputRef.current.value = defaultItem.text
        } else {
          setSelectedItem(undefined)
          formMethods.setValue(name, undefined)
          if (inputRef && inputRef.current) inputRef.current.value = ''
        }
      }
    },
    [onSearch, defaultItem, formMethods, name]
  )

  const renderOption = (item: Finder2Item) => {
    const selected = item.value === selectedItem?.value
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <li
        key={item.value}
        className={classNames(
          'relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-teal-600 hover:text-white',
          selected ? 'bg-teal-600 text-white' : 'text-slate-900'
        )}
        onClick={() => {
          handleSelectItem(item)
        }}
      >
        <span
          className={classNames('block truncate', selected && 'font-semibold')}
        >
          {item.text}
        </span>
        {selected && (
          <span
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-4',
              selected ? 'text-white' : 'text-teal-600'
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </li>
    )
  }

  useEffect(() => {
    handleSearch('')
  }, [handleSearch])

  return (
    <div
      className={classNames(
        'sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5',
        className
      )}
    >
      <input
        className="hidden"
        type="text"
        id={name}
        {...formMethods.register(name)}
      />
      <label className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2 align-middle">
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="relative mt-1">
        <input
          ref={inputRef}
          className="w-full rounded-md border border-slate-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 sm:text-sm disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
          type="text"
          onChange={(e) => {
            setKeyword(e.target.value)
          }}
          onFocus={() => setIsOpen(true)}
          disabled={disabled}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          <ChevronUpDownIcon
            className="h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </button>
        {isOpen && (
          <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ul>
              {filteredItems.length === 0 && (
                <li className="relative cursor-default select-none py-2 pl-3 pr-9">
                  데이터가 없습니다.
                </li>
              )}
              {filteredItems.map((item) => renderOption(item))}
            </ul>
          </div>
        )}
      </div>
      <ErrorMessage error={error} name={name} />
    </div>
  )
}

export default Finder2
