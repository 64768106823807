import React, { TextareaHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../error-message/ErrorMessage'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string
  name: string
  label: string
}

export default function TextAreaSideLabelForm({
  name,
  id,
  label,
  placeholder,
  defaultValue,
  required,
  disabled,
}: TextAreaProps): JSX.Element {
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-slate-200 sm:pt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-slate-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="mt-1 sm:col-span-2">
        <textarea
          rows={4}
          id={id}
          className="block w-full rounded-md border-slate-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          {...formMethods.register(name)}
        />
        <ErrorMessage error={error} name={name} />
      </div>
    </div>
  )
}
