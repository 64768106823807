import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  CreateDocumentRequest,
  UpdateDocumentRequest,
} from '../types/api/document'
import { DocumentModel } from '../types/Document'

export const createDocumentApi = async (
  data: CreateDocumentRequest
): Promise<void> => {
  const formData = new FormData()
  formData.append('title', data.title)
  formData.append('contents', `${data.contents || ''}`)
  if (data.parentId) formData.append('parentId', `${data.parentId}`)
  formData.append('type', `${data.type}`)
  formData.append('customerId', data.customerId)
  data.files?.forEach((file) => {
    formData.append('files', file)
  })
  return api.post(`/documents`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const updateDocumentApi = async (
  id: string,
  data: UpdateDocumentRequest
): Promise<DocumentModel> => {
  return api.patch(`/documents/${id}`, data)
}

export const getDocumentsApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  customerId?: string
): Promise<ListDto<DocumentModel>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  return api.get(
    `/documents?page=${page}&limit=${limit}${customerQuery}${searchQuery}`
  )
}

export const getDocumentApi = async (id: string): Promise<DocumentModel> => {
  return api.get(`/documents/${id}`)
}

export const deleteDocumentApi = async (id: string): Promise<void> => {
  return api.delete(`/documents/${id}`)
}

export const getTempDocumentsApi = async (
  { page, limit, searchField, keyword }: ListRequestDto,
  customerId: string,
  parentId?: string
): Promise<ListDto<DocumentModel>> => {
  const searchQuery =
    searchField && keyword
      ? `&searchField=${searchField}&keyword=${keyword}`
      : ''
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  const parentQuery = parentId ? `&parentId=${parentId}` : ''
  return api.get(
    `/documents/temps?page=${page}&limit=${limit}${customerQuery}${searchQuery}${parentQuery}`
  )
}

export const getTempDocumentApi = async (
  id: string
): Promise<DocumentModel> => {
  return api.get(`/documents/temps/${id}`)
}

export default {}
