import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getPlaceIconListApi } from '../api/place-icon.api'
import ManageListLayout from '../components/layout/ManageListLayout'
import ImageList from '../components/lists/ImageList'
import { PrimaryButtonStyle } from '../styles/button.style'
import { ImageFileModel } from '../types/File'
import PlaceIcon from '../types/PlaceIcon'

function PlaceIconList() {
  const navigate = useNavigate()
  const [items, setItems] = useState<ImageFileModel[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const limit = 100
  useEffect(() => {
    getPlaceIconListApi({ page, limit }).then((data) => {
      setItems(data.items.map((item) => ({ ...item, label: item.iconName })))
      setTotal(data.total)
    })
  }, [page])
  return (
    <ManageListLayout
      title="아이콘 관리"
      description="장소, 안내판에 사용할 아이콘을 관리하는 페이지 입니다."
      createButtonChildren={
        <Link to="/place-icon/create" className={PrimaryButtonStyle}>
          아이콘 생성하기
        </Link>
      }
    >
      <ImageList
        onClickCallback={(file: ImageFileModel) => {
          navigate(`/place-icon/edit/${file.id}`)
        }}
        items={items}
      />
    </ManageListLayout>
  )
}

export default PlaceIconList
