import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import React, {
  FormEvent,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
// import type { DropTargetMonitor } from 'react-dnd'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import ErrorMessage from '../error-message/ErrorMessage'
import { fileListToArray } from '../../helpers/common.helper'
import FileForm from './FileForm'
import SideLabel from './SideLabel'

type FileProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  name: string
  label: string
  onDropCallback?: (file: File[]) => Promise<void>
  showItem?: boolean
}

export default function FileSideLabelForm({
  name,
  id,
  label,
  disabled,
  required,
  onDropCallback,
  showItem,
}: FileProps) {
  return (
    <SideLabel id={id} label={label}>
      <FileForm
        id={id}
        name={name}
        required={required}
        showItem={showItem}
        disabled={disabled}
      />
    </SideLabel>
  )
}
