import React, {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { classNames } from '../../helpers/common.helper'
import { SecondsButtonStyle } from '../../styles/button.style'
import { DefaultInputStyle } from '../../styles/input.style'
import { ImageFileModel } from '../../types/File'
import Button from '../button/Button'
import ErrorMessage from '../error-message/ErrorMessage'
import ImageList from '../lists/ImageList'

type ImageSelectorProps = SelectHTMLAttributes<HTMLSelectElement> & {
  onSelectCallback?: (id: string) => void
  onSearchCallback?: (keyword: string) => Promise<ImageFileModel[]>
  defaultItem?: ImageFileModel
  name?: string
}

function ImageSelector({
  onSelectCallback,
  onSearchCallback,
  defaultItem,
  name,
}: ImageSelectorProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [items, setItems] = useState<ImageFileModel[]>([])
  const [selectedItem, setSelectedItem] = useState<ImageFileModel>()

  const methods = useFormContext()
  const registerForm = () => {
    if (name) return methods.register(name)
    return {}
  }

  const handleSelectItem = (file: ImageFileModel): void => {
    setSelectedItem(items.find((item) => item.id === file.id))
    if (onSelectCallback && file.id) onSelectCallback(file.id)
    setIsOpen(false)
  }

  const handleSearch = useCallback(
    async (query: string): Promise<void> => {
      if (onSearchCallback) {
        const data = await onSearchCallback(query)
        setItems(data)
      }
    },
    [onSearchCallback]
  )
  useEffect(() => {
    if (defaultItem) setSelectedItem(defaultItem)
  }, [defaultItem])

  return (
    <div>
      <input type="text" className="hidden" {...registerForm()} />
      {selectedItem && selectedItem.path && (
        <div className="mb-4 group block w-40 overflow-hidden rounded-lg bg-slate-100">
          <img
            src={selectedItem.path}
            alt={`${selectedItem.label} 아이콘`}
            className="w-full pointer-events-none object-cover group-hover:opacity-75"
          />
        </div>
      )}

      <Button
        type="button"
        className={classNames(SecondsButtonStyle)}
        onClick={() => {
          setIsOpen(!isOpen)
          handleSearch('')
        }}
      >
        이미지 선택
      </Button>
      {isOpen && (
        <div className="mt-4">
          <input
            type="text"
            className={classNames(DefaultInputStyle, 'mb-2')}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <ImageList items={items} onClickCallback={handleSelectItem} />
        </div>
      )}
      {name && methods.formState.errors[name] && (
        <ErrorMessage error={methods.formState.errors[name]} name={name} />
      )}
    </div>
  )
}

export default ImageSelector
