import { api } from '../lib/api'
import { ListDto, ListRequestDto } from '../types/api/common'
import {
  CreateSignBoardRequest,
  UpdateSignBoardRequest,
} from '../types/api/sign-board'
import { SignBoardModel } from '../types/SignBoard'

export default {}

export const getSignBoardListApi = async (
  { page, limit }: ListRequestDto,
  customerId?: string,
  hasFile?: boolean
): Promise<ListDto<SignBoardModel>> => {
  const customerQuery = customerId ? `&customerId=${customerId}` : ''
  const hasFileQuery = hasFile === undefined ? '' : `&hasFile=${hasFile}`
  return api.get(
    `/sign-board?page=${page}&limit=${limit}${customerQuery}${hasFileQuery}`
  )
}

export const getSignBoardApi = async (id: string): Promise<SignBoardModel> => {
  return api.get(`/sign-board/${id}`)
}

export const createSignBoardApi = async (
  data: CreateSignBoardRequest
): Promise<SignBoardModel> => {
  return api.post(`/sign-board`, data)
}

export const updateSignBoardApi = async (
  id: string,
  data: UpdateSignBoardRequest
): Promise<SignBoardModel> => {
  return api.patch(`/sign-board/${id}`, data)
}

export const deleteSignBoardApi = async (id: string) => {
  return api.delete(`/sign-board/${id}`)
}

export const signBoardUploadApi = async (
  id: string,
  files: File[]
): Promise<SignBoardModel> => {
  const formData = new FormData()
  files.forEach((f: File) => {
    formData.append('files', f)
  })
  return api.post(`/sign-board/${id}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const signBoardFileDelete = async (
  signBoardId: string,
  fileId: string
) => {
  return api.delete(`/sign-board/${signBoardId}/file/${fileId}`)
}
