import React, { SelectHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'

type Select2Option = {
  text: string
  value: string
}
type Select2FormProps = SelectHTMLAttributes<HTMLSelectElement> & {
  name: string
  options?: Select2Option[]
}
function Select2Form({
  name,
  options,
  onChange,
  children,
  disabled,
}: Select2FormProps) {
  const methods = useFormContext()
  const registerForm = () => {
    if (methods) return methods.register(name)
    return {}
  }
  return (
    <select
      autoComplete={`${name}-name`}
      className="block w-full max-w-lg rounded-md border-slate-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
      aria-invalid="true"
      aria-describedby={`${name}-error`}
      {...registerForm()}
      onChange={onChange}
      disabled={disabled}
    >
      {children}
      {options?.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.text}
        </option>
      ))}
    </select>
  )
}

export default Select2Form
