import Joi from 'joi'
import validator from '../lib/validate'

export default {}

export const PlaceCreateValidation = validator().object({
  contents: Joi.string().optional(),
  parentId: Joi.string().optional(),
  customerId: Joi.string().required(),
  buildingId: Joi.string().optional(),
  floorId: Joi.string().optional(),
  placeType: Joi.string().required(),
  placeCategory: Joi.optional(),
  title: Joi.string().required(),
  summary: Joi.optional(),
  notice: Joi.optional(),
  hour: Joi.optional(),
  contactTel: Joi.optional(),
  contactName: Joi.optional(),
  available: Joi.boolean().required(),
  iconId: Joi.optional(),
  latitude: Joi.optional(),
  longitude: Joi.optional(),
})

export const PlaceUpdateValidation = validator().object({
  iconName: Joi.string().required(),
  category: Joi.string().required(),
  file: Joi.allow(),
})
