import React, { Fragment, useEffect, useState } from 'react'
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useRecoilValue } from 'recoil'
import { Transition } from '@headlessui/react'
import { toastAtom } from '../../recoil/notification.recoil'

export default function Toast() {
  const state = useRecoilValue(toastAtom)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(!!state)
    setTimeout(() => {
      setOpen(false)
    }, state?.duration || 3000)
  }, [state])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 -translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-4"
      >
        <div className="fixed top-0 z-10 w-full rounded-md bg-teal-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-teal-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-teal-800">
                {state?.title}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-teal-50 p-1.5 text-teal-500 hover:bg-teal-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 focus:ring-offset-teal-50"
                  onClick={() => {
                    if (state?.onClick) state?.onClick()
                    setOpen(false)
                  }}
                >
                  <span className="sr-only">닫기</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  )
}
