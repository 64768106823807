import axios, { AxiosRequestConfig } from 'axios'
import { ACCESS_TOKEN } from '../constants/auth.constant'
import { showAlert } from './alert'
// eslint-disable-next-line import/no-cycle
import { logout } from './auth'
import lang from './lang'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const customConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  }
  return {
    ...config,
    ...customConfig,
  }
})
const rejectInterceptor = (err: any) => {
  const { response } = err
  if (!response) return Promise.reject(err)
  switch (response.status) {
    case 500:
      showAlert('에러가 발생하였습니다.')
      break
    case 409:
      showAlert(lang().getMessage(response.data.errorCode))
      break
    case 401:
      showAlert('로그인이 필요합니다.', {
        onClick: () => {
          logout()
          window.location.href = '/login'
        },
      })
      break
    case 403:
      showAlert('접근권한이 없습니다.')
      break
    case 400:
      showAlert('잘못된 요청입니다.')
      break
    default:
      break
  }

  return Promise.reject(response)
}
api.interceptors.response.use((res) => res.data, rejectInterceptor)
export default {}
