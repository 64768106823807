import { TrashIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, { HTMLAttributes } from 'react'
import { byteToSize, getSource } from '../../helpers/common.helper'
import { ImageFileModel } from '../../types/File'

type ImageListProps = HTMLAttributes<HTMLElement> & {
  items: ImageFileModel[]
  onClickCallback?: (file: ImageFileModel) => void
  onDeleteCallback?: (id: string) => void
}

export default function ImageList({
  items,
  onClickCallback,
  onDeleteCallback,
}: ImageListProps) {
  const handleOpenButton = (url: string) => {
    window.open(url)
  }
  const handleDeleteButton = (id: string) => {
    if (onDeleteCallback) onDeleteCallback(id)
  }

  if (items.length === 0) {
    return (
      <div className="sm:text-sm sm:mt-px sm:pt-2">
        등록된 이미지가 없습니다.
      </div>
    )
  }

  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {items.map((file, i) => (
        <li key={`${file.id}-${new Date().getTime()}`} className="relative">
          <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-slate-100 focus-within:ring-2 focus-within:ring-teal-500 focus-within:ring-offset-2 focus-within:ring-offset-slate-100">
            <img
              src={getSource(file)}
              alt=""
              className="pointer-events-none object-cover group-hover:opacity-75"
            />
            <button
              type="button"
              className="absolute inset-0 focus:outline-none"
              onClick={() => {
                if (onClickCallback && file) {
                  onClickCallback(file)
                  return
                }
                if (file.path) handleOpenButton(file.path)
              }}
            >
              <span className="sr-only">
                이미지 크게 보기 {file.originalFilename}
              </span>
            </button>
          </div>
          <div className="mt-2 ">
            {file.label && (
              <p className="block truncate text-sm font-medium text-slate-900">
                {file.label}
              </p>
            )}
            {file.originalFilename && (
              <p className="pointer-events-none block truncate text-sm font-medium text-slate-900">
                {file.originalFilename}
              </p>
            )}
            {file.size && (
              <p className="pointer-events-none block text-sm font-medium text-slate-500">
                {byteToSize(file.size)}
              </p>
            )}
            {file.createdAt && (
              <p className="pointer-events-none block text-sm font-medium text-slate-500">
                {dayjs(file.createdAt).format('YYYY-MM-DD hh:mm:ss')}
              </p>
            )}
            {file.id && onDeleteCallback && (
              <button
                type="button"
                className="absolute bottom-1 right-1"
                onClick={() => {
                  if (file.id) handleDeleteButton(file.id)
                }}
              >
                <TrashIcon className="w-4" />
                <span className="sr-only">파일 삭제</span>
              </button>
            )}
          </div>
        </li>
      ))}
    </ul>
  )
}
