import React, { SelectHTMLAttributes, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../error-message/ErrorMessage'

export interface OptionProps {
  text: string
  value: string
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string
  name: string
  label: string
  options?: OptionProps[]
  loadData?: () => Promise<OptionProps[]>
}
export default function SelectForm(props: SelectProps): JSX.Element {
  const {
    name,
    id,
    label,
    options,
    onChange,
    disabled,
    defaultValue,
    loadData,
  } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const [items, setItems] = useState<OptionProps[]>([])

  useEffect(() => {
    if (options) {
      setItems(options)
    }
    if (loadData) {
      loadData().then((data) => {
        setItems(data)
      })
    }

    const formValue = formMethods.getValues()[name]
    if (!formValue) {
      if (options && options.length > 0) {
        formMethods.setValue(name, options[0].value)
      }
    }
  }, [options, loadData, formMethods, name])

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-slate-700">
        {label}
      </label>
      <select
        id={id}
        autoComplete={`${name}-name`}
        className="mt-1 block w-full py-2 px-3 border text-slate-900 border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
        aria-invalid="true"
        aria-describedby={`${name}-error`}
        {...formMethods.register(name)}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {items?.map((op) => (
          <option value={op.value} key={`${id}-${op.value}`}>
            {op.text}
          </option>
        ))}
      </select>
      <ErrorMessage error={error} name={name} />
    </div>
  )
}
