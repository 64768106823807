import React, { useEffect, useState } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import {
  createCustomerApi,
  deleteCustomerApi,
  getCustomerApi,
  updateCustomerApi,
} from '../api/customer.api'
import Button from '../components/button/Button'
import { ADMIN_TITLE } from '../constants/title'
import { showAlert } from '../lib/alert'
import { CreateCustomerRequest } from '../types/api/customer'
import { CustomerCreateValidation } from '../validations/customer.validation'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import { customersSelector } from '../recoil/customer.recoil'
import SideLabel from '../components/forms/SideLabel'

export default function CustomerCreate(): JSX.Element {
  const methods = useForm({
    resolver: joiResolver(CustomerCreateValidation),
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const [title, setTitle] = useState('업체 생성')
  const customersRefresh = useRecoilRefresher_UNSTABLE(customersSelector)

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updateCustomerApi(id, formData as CreateCustomerRequest)
    } else {
      await createCustomerApi(formData as CreateCustomerRequest)
    }
    customersRefresh()
    navigate('/customers')
  }

  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      desc: '삭제하게 되면 연관된 유저와 NFC를 사용할 수 없습니다.',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteCustomerApi(id)
          customersRefresh()
          navigate('/customers')
        },
      },
    })
  }

  useEffect(() => {
    document.title = `업체 관리 - ${ADMIN_TITLE}`
    if (id) {
      setTitle('업체 수정')
      getCustomerApi(id).then((data) => {
        methods.setValue('id', data.id)
        methods.setValue('name', data.name)
      })
    }
  }, [id, methods])
  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="업체를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/customers" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
    >
      <InputSideLabelForm
        id="id"
        name="id"
        type="text"
        label="ID"
        disabled={!!id}
      >
        {!id && (
          <p className="text-sm text-red-600">* 이후 변경할 수 없습니다.</p>
        )}
      </InputSideLabelForm>
      <InputSideLabelForm id="name" name="name" type="text" label="업체명" />
    </ManageCreateLayout>
  )
}
