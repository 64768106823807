import { NfcContentTypeEnum } from '../types/Nfc'

export default {}

export const nfcTypeToText = (type?: NfcContentTypeEnum): string => {
  switch (type) {
    case NfcContentTypeEnum.DOCUMENT:
      return '문서'
    case NfcContentTypeEnum.PLACE:
      return '장소'
    case NfcContentTypeEnum.LINK:
      return '링크'
    default:
      return '정의되지 않음'
  }
}
