import React, { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { NavLink, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  filteredMenuSelector,
  sideBarToggleAtom,
} from '../../recoil/navigation.recoil'
import { checkLocation, classNames } from '../../helpers/common.helper'
import LogoIcon from '../icons/logo.icon'

export default function Nav(): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sideBarToggleAtom)
  const navigation = useRecoilValue(filteredMenuSelector)
  const location = useLocation()
  return (
    <nav>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-teal-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">사이드바 닫기</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <p className="flex flex-row justify-start items-center space-x-2 text-white">
                    <LogoIcon className="h-8 w-auto" /> <span>다가다</span>
                  </p>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        onClick={() => setSidebarOpen(false)}
                        className={classNames(
                          checkLocation(
                            location.pathname,
                            item.href,
                            item.exact
                          )
                            ? 'bg-teal-800 text-white'
                            : 'text-teal-100 hover:bg-teal-600',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <span>
                          <item.icon
                            className="mr-4 flex-shrink-0 h-6 w-6 text-teal-300"
                            aria-hidden="true"
                          />
                          {item.name}
                        </span>
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 bg-teal-700 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <p className="flex flex-row justify-start items-center space-x-2 text-white">
              <LogoIcon className="h-8 w-auto" /> <span>다가다</span>
            </p>
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            {/* <Dropdown
              name={usableCustomers[0]?.name || 'select customer'}
              loadData={loadCustomers}
            /> */}
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <NavLink key={item.name} to={item.href}>
                  <span
                    className={classNames(
                      checkLocation(location.pathname, item.href, item.exact)
                        ? 'bg-teal-800 text-white'
                        : 'text-teal-100 hover:bg-teal-600',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-teal-300"
                      aria-hidden="true"
                    />
                    {item.name}
                  </span>
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </nav>
  )
}
