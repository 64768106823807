import { joiResolver } from '@hookform/resolvers/joi'
import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { bulkCreateSurveyApi } from '../api/survey.api'
import Button from '../components/button/Button'
import CustomerSelectorSideLabelForm from '../components/customers/selectorWithLabelForm'
import BasePlaceFinder from '../components/finders/BasePlaceFinder'
import FileSideLabelForm from '../components/forms/FileSideLabel'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import SimpleAlert from '../components/notifications/SimpleAlert'
import useSurvey from '../hooks/survey.hook'
import { DefaultButtonStyle, PrimaryButtonStyle } from '../styles/button.style'
import { BulkCreateSurveyRequest } from '../types/api/survey'
import { SurveyImportDataValidation } from '../validations/survey.validation'

function SurveyBulkCreate2() {
  const navigate = useNavigate()
  const { id } = useParams()
  const methods = useForm({
    resolver: joiResolver(SurveyImportDataValidation),
  })
  const [title, setTitle] = useState('현장조사 데이터 엑셀로 추가')
  const { refreshVersions, setVersion } = useSurvey()

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const data = await bulkCreateSurveyApi({
      ...formData,
      buildingId: formData.basePlaceId,
    } as BulkCreateSurveyRequest)
    refreshVersions()
    setVersion(data)
    navigate('/survey')
  }

  return (
    <ManageCreateLayout
      methods={methods}
      onSubmit={handleSubmit}
      title={title}
      description="엑셀을 이용하여 현장조사 데이터를 생성합니다."
      cancelButton={
        <Link to="/survey" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
    >
      <SimpleAlert className="my-2">
        자동으로 버전이 추가되며 추가된 버전에 데이터가 생성됩니다.
      </SimpleAlert>
      <CustomerSelectorSideLabelForm required />
      <BasePlaceFinder required />
      <FileSideLabelForm
        id="file"
        name="file"
        type="file"
        label="엑셀파일 선택"
        required
        showItem
      />
    </ManageCreateLayout>
  )
}

export default SurveyBulkCreate2
