import dayjs from 'dayjs'
import React, { useEffect, useState, version } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../components/button/Button'
import ManageListLayout from '../components/layout/ManageListLayout'
import Paginate from '../components/paginate'
import Select from '../components/select/Select'
import { CustomerSelector } from '../components/survey/ListFilters'
import { classNames } from '../helpers/common.helper'
import useSurvey from '../hooks/survey.hook'
import { PrimaryButtonStyle } from '../styles/button.style'
import {
  TableStyle,
  TheadStyle,
  ThStartStyle,
  ThStyle,
  ThEndStyle,
  TbodyStyle,
  TdStartStyle,
  TdStyle,
  TdEndStyle,
} from '../styles/table.style'

function SurveyVersionList() {
  const navigate = useNavigate()
  const { versions, setVersion } = useSurvey()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)

  const handleOnChangePage = (p: number) => setPage(p)

  useEffect(() => {
    setTotal(versions.length)
  }, [versions])
  return (
    <ManageListLayout
      title="현장조사 버전 관리"
      description="현장조사 버전을 관리하는 페이지 입니다"
      createButtonChildren={
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
          <Link to="/survey/bulk-create" className={PrimaryButtonStyle}>
            엑셀로 데이터 생성하기
          </Link>
          <Link to="/survey-version/create" className={PrimaryButtonStyle}>
            현장조사 버전 생성하기
          </Link>
        </div>
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col justify-between items-end sm:flex-col md:flex-row gap-2 ">
          <div className="flex flex-row gap-2">
            <CustomerSelector />
            <Select
              label="페이지당 건수"
              options={['5', '10', '20', '50', '100'].map((item) => ({
                text: item,
                value: item,
              }))}
              defaultValue={20}
              onChange={(e) => {
                setLimit(Number(e.target.value))
                setPage(1)
              }}
            />
          </div>
          <div className="text-slate-700">전체 수: {total}</div>
        </div>
      </div>
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    ID
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    버전명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    업체명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    데이터 수
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    데이터 생성 유무
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    작성일
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {versions.length === 0 && (
                  <tr>
                    <td colSpan={4} className={classNames(TdStartStyle)}>
                      등록된 데이터가 없습니다
                    </td>
                  </tr>
                )}
                {versions.map((item, i: number) => (
                  <tr key={item.id}>
                    <td className={classNames(TdStartStyle)}>
                      {(page - 1) * limit + i + 1}
                    </td>
                    <td className={classNames(TdStyle)}>{item.id}</td>
                    <td className={classNames(TdStyle)}>
                      {item.name}{' '}
                      <Button
                        className="text-teal-600 hover:text-teal-900 ml-1"
                        onClick={() => {
                          setVersion(versions.find((v) => v.id === item.id))
                          navigate('/survey')
                        }}
                      >
                        관리
                        <span className="sr-only">, {item.name}</span>
                      </Button>
                    </td>
                    <td className={classNames(TdStyle)}>
                      {item.customer.name}
                    </td>
                    <td className={classNames(TdStyle)}>
                      {item.surveyList?.length}개
                    </td>
                    <td className={classNames(TdStyle)}>
                      {item.publishedAt ? (
                        <span className="text-teal-600">생성됨</span>
                      ) : (
                        <span className="text-red-600">미생성</span>
                      )}
                    </td>
                    <td className={classNames(TdStyle)}>
                      {dayjs(item.createdAt).format('YYYY-MM-DD hh:mm:ss')}
                    </td>
                    <td
                      className={classNames(
                        TdEndStyle,
                        'flex justify-end space-x-4'
                      )}
                    >
                      <Link
                        to={`/survey-version/view/${item.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        상세 <span className="sr-only">, {item.name}</span>
                      </Link>
                      <Link
                        to={`/survey-version/edit/${item.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        수정
                        <span className="sr-only">, {item.name}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default SurveyVersionList
