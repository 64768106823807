import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useFormContext } from 'react-hook-form'
import { getDocumentsApi } from '../../api/document.api'
import { searchPlaceListApi } from '../../api/place.api'
import { customerAtom } from '../../recoil/customer.recoil'
import Finder, { FinderItem } from '../finders/Finder'
import InputSideLabelForm from '../forms/InputSideLabel'
import SideLabel from '../forms/SideLabel'
import Select from '../select/Select'

const types = [
  { value: 'document', text: '문서' },
  { value: 'place', text: '플레이스' },
  { value: 'link', text: '링크' },
]

type QrContentFormProps = HTMLAttributes<HTMLElement> & {
  document?: FinderItem
  place?: FinderItem
  contentType?: string
  required?: boolean
}

export default function QrContentForm({
  document,
  place,
  contentType,
  required,
}: QrContentFormProps) {
  const methods = useFormContext()
  const [currentContentType, setCurrentContentType] =
    useState<string>('document')
  const customer = useRecoilValue(customerAtom)
  const loadPlaces = async (keyword: string) => {
    return searchPlaceListApi(1, 10000, keyword, customer?.id).then((data) => {
      return data.items.map((item) => {
        return { text: item.title, value: item.id }
      })
    })
  }
  const loadDocuments = async (keyword: string) => {
    return getDocumentsApi(
      { page: 1, limit: 50, searchField: 'title', keyword },
      customer?.id
    ).then((data) => {
      return data.items.map((item) => {
        return { text: item.title, value: item.id }
      })
    })
  }

  useEffect(() => {
    setCurrentContentType(contentType || 'document')
  }, [contentType])

  return (
    <>
      <SideLabel label="컨텐츠 종류" required={required}>
        <Select
          options={types}
          onChange={(e) => {
            const type = e.target.value
            setCurrentContentType(type)
            if (type !== 'document') methods.setValue('documentId', undefined)
            if (type !== 'place') methods.setValue('placeId', undefined)
            if (type !== 'link') methods.setValue('link', undefined)
          }}
          defaultValue="document"
        />
      </SideLabel>
      {currentContentType === 'document' && (
        <Finder
          required={required}
          id="documentId"
          name="documentId"
          label="문서 선택"
          searchCallback={loadDocuments}
          defaultItem={document}
        />
      )}
      {currentContentType === 'place' && (
        <Finder
          required={required}
          id="placeId"
          name="placeId"
          label="장소 선택"
          searchCallback={loadPlaces}
          defaultItem={place}
        />
      )}
      {currentContentType === 'link' && (
        <InputSideLabelForm
          required={required}
          type="url"
          id="link"
          name="link"
          label="링크"
          placeholder="ex) https://everyoncorp.com"
          className={`${currentContentType === 'link' ? '' : 'hidden'}`}
        />
      )}
    </>
  )
}
