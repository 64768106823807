export enum PlaceType {
  Building = 'building',
  Floor = 'floor',
  Place = 'place',
  Facilities = 'facilities',
  Object = 'object',
}
export enum PlaceCategory {
  Office = 'office',
  ShowerRoom = 'showerRoom',
  Restaurant = 'restaurant',
  RestArea = 'restArea',
  SecurityOffice = 'securityOffice',
  MachineRoom = 'machineRoom',
  MachineManagementRoom = 'machineManagementRoom',
  NurseOffice = 'nurseOffice',
  Gym = 'gym',
  SwimmingPool = 'swimmingPool',
  BroadcastingRoom = 'broadcastingRoom',
  ConsultingRoom = 'consultingRoom',

  Ramp = 'ramp',
  Stairs = 'stairs',
  PublicToilet = 'publicToilet',
  ManAndWomanToilet = 'manAndWomanToilet',
  ManShowerRoom = 'manShowerRoom',
  ManDisabledToilet = 'manDisabledToilet',
  ManToilet = 'manToilet',
  RampDown = 'rampDown',
  StairsDown = 'stairsDown',
  Elevator = 'elevator',
  WomanShowerRoom = 'womanShowerRoom',
  WomanDisabledToilet = 'womanDisabledToilet',
  WomanToilet = 'womanToilet',
  RampUp = 'rampUp',
  StairsUp = 'stairsUp',
  DisabledToilet = 'disabledToilet',
  Parking = 'parking',
  EntranceDoor = 'entranceDoor',
  FreightElevator = 'freightElevator',
  Toilet = 'toilet',
}

export enum SignBoardType {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export const PlaceTypes = [
  { text: '공간', value: PlaceType.Place },
  { text: '편의시설', value: PlaceType.Facilities },
  { text: '사물', value: PlaceType.Object },
]

export const PlaceCategories = [
  { text: '사무실', value: PlaceCategory.Office },
  { text: '샤워실', value: PlaceCategory.ShowerRoom },
  { text: '식당', value: PlaceCategory.Restaurant },
  { text: '휴게실', value: PlaceCategory.RestArea },
  { text: '경비실', value: PlaceCategory.SecurityOffice },
  { text: '기계실', value: PlaceCategory.MachineRoom },
  { text: '기계관리실', value: PlaceCategory.MachineManagementRoom },
  { text: '보건실', value: PlaceCategory.NurseOffice },
  { text: '체육관', value: PlaceCategory.Gym },
  { text: '수영장', value: PlaceCategory.SwimmingPool },
  { text: '방송실', value: PlaceCategory.BroadcastingRoom },
  { text: '상담실', value: PlaceCategory.ConsultingRoom },
]

export const FacilitiesCategories = [
  { text: '화장실', value: PlaceCategory.Toilet },
  { text: '공용 화장실', value: PlaceCategory.PublicToilet },
  { text: '남녀 화장실', value: PlaceCategory.ManAndWomanToilet },
  { text: '여자 장애인 화장실', value: PlaceCategory.WomanDisabledToilet },
  { text: '남자 장애인 화장실', value: PlaceCategory.ManDisabledToilet },
  { text: '남자 화장실', value: PlaceCategory.ManToilet },
  { text: '여자 화장실', value: PlaceCategory.WomanToilet },
  { text: '장애인 화장실', value: PlaceCategory.DisabledToilet },
  { text: '계단실', value: PlaceCategory.Stairs },
  { text: '올라가는 계단', value: PlaceCategory.StairsUp },
  { text: '내려가는 계단', value: PlaceCategory.StairsDown },
  { text: '엘리베이터', value: PlaceCategory.Elevator },
  { text: '화물 엘리베이터', value: PlaceCategory.FreightElevator },
  { text: '경사로실', value: PlaceCategory.Ramp },
  { text: '올라가는 경사로', value: PlaceCategory.RampUp },
  { text: '내려가는 경사로', value: PlaceCategory.RampDown },
  { text: '출입문', value: PlaceCategory.EntranceDoor },
  { text: '주차장', value: PlaceCategory.Parking },
  { text: '남자 샤워실', value: PlaceCategory.ManShowerRoom },
  { text: '여자 샤워실', value: PlaceCategory.WomanShowerRoom },
]

export const ObjectCategories = [{ text: '오브젝트', value: 'object' }]

export const PlaceTypeTree = {
  [PlaceType.Building]: [],
  [PlaceType.Floor]: [],
  [PlaceType.Place]: PlaceCategories,
  [PlaceType.Facilities]: FacilitiesCategories,
  [PlaceType.Object]: ObjectCategories,
}

export const SignBoardTypes = [
  { text: '대', value: SignBoardType.Large },
  { text: '중', value: SignBoardType.Medium },
  { text: '소', value: SignBoardType.Small },
]

export default {}
