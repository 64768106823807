import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteAdminUserApi } from '../api/admin-user.api'
import { deleteCustomerApi, getCustomerApi } from '../api/customer.api'
import Button from '../components/button/Button'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { CustomerType } from '../types/Customer'

function CustomerView(): JSX.Element {
  const { id } = useParams()
  const [customer, setCustomer] = useState<CustomerType>()
  const navigate = useNavigate()
  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteCustomerApi(id)
          navigate('/customers')
        },
      },
    })
  }
  useEffect(() => {
    if (id) {
      getCustomerApi(id).then((data) => {
        setCustomer(data)
      })
    }
  }, [id])
  return (
    <ViewLayout
      title="업체 상세"
      description="업체를 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/customers" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/customers/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="ID">{customer?.id}</SideLabel>
      <SideLabel label="업체명">{customer?.name}</SideLabel>
    </ViewLayout>
  )
}
export default CustomerView
