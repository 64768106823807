import Joi, { CustomHelpers } from 'joi'
import validator from '../lib/validate'

export default {}

export const PlaceIconCreateValidation = validator().object({
  iconName: Joi.string().required(),
  category: Joi.string().required(),
  file: Joi.custom((value: FileList, helpers: CustomHelpers) => {
    if (value.length === 0) {
      return helpers.message({ custom: '파일은 필수 입니다.' })
    }
    return value
  }),
})

export const PlaceIconUpdateValidation = validator().object({
  iconName: Joi.string().required(),
  category: Joi.string().required(),
  file: Joi.allow(),
})
