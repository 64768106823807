import { AlertPropType, alertAtom } from '../recoil/notification.recoil'
import { setRecoilExternalState } from './recoilExternalState'

export const showAlert = (
  title: AlertPropType | string,
  props?: AlertPropType
) => {
  let newProps = props || ({} as AlertPropType)
  if (typeof title === 'string') {
    newProps.title = title
  } else {
    newProps = {
      ...props,
      ...title,
    }
  }
  setRecoilExternalState(alertAtom, newProps)
}

export const hideAlert = () => setRecoilExternalState(alertAtom, null)

export default {}
