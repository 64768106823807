import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

type SearchInputProps = {
  className?: string
  onSearch?: (searchField?: string, keyword?: string) => void
}

function SearchInput({ className, onSearch }: SearchInputProps) {
  return (
    <div className={className}>
      <label htmlFor="search" className="sr-only">
        검색
      </label>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-slate-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-md border-slate-300 pl-10 focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
          placeholder="검색"
          onKeyDown={(e: any) => {
            if (e.key === 'Enter' && onSearch) {
              onSearch('name', e.target.value)
            }
          }}
        />
      </div>
    </div>
  )
}

export default SearchInput
