import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useEditor, EditorContent, Editor as Editor2 } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Youtubue from '@tiptap/extension-youtube'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import { useTable } from './functions/Table'
import MenuBar from './Menu'
import './styles.scss'
import ErrorMessage from '../error-message/ErrorMessage'
import { EditorExtensionProps } from './types/editor.type'
import { ImageFileModel } from '../../types/File'
import ImageList from '../lists/ImageList'
import { getSource } from '../../helpers/common.helper'

type EditorProps = {
  name?: string
  content?: string
}

export default function Editor({ name, content }: EditorProps) {
  const table = useTable()
  const methods = useFormContext()
  const [uploadImages, setUploadImages] = useState<File[]>([])
  const tiptapEditor = useEditor({
    extensions: [
      StarterKit,
      Image,
      Youtubue,
      ...table,
      Link.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'right', 'center'],
      }),
    ],
    onUpdate: ({ editor }) => {
      if (name && methods) {
        methods.setValue(name, editor.getHTML())
      }
    },
    editorProps: {
      attributes: {
        class: 'editor-document',
      },
    },
    content,
  })

  const imageUploaded = async (files: File[]) => {
    setUploadImages(files)
  }

  const imageClick = async (file: ImageFileModel, editor: Editor2 | null) => {
    if (!editor) return
    editor
      .chain()
      .setImage({ src: getSource(file) })
      .run()
  }

  useEffect(() => {
    tiptapEditor?.commands.setContent(content || '')
  }, [content, tiptapEditor])

  return (
    <>
      <div className="editor flex flex-col gap-2">
        <MenuBar editor={tiptapEditor} image={{ imageUploaded }} />
        <EditorContent editor={tiptapEditor} />
        {/* <ImageList
          items={uploadImages}
          onClickCallback={(file) => imageClick(file, tiptapEditor)}
        /> */}
      </div>
      {name && methods.formState.errors[name] && (
        <ErrorMessage error={methods.formState.errors[name]} name={name} />
      )}
    </>
  )
}
