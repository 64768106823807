import { joiResolver } from '@hookform/resolvers/joi'
import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  createPlaceIconApi,
  deletePlaceIconApi,
  getPlaceIconApi,
  updatePlaceIconApi,
} from '../api/place-icon.api'
import Button from '../components/button/Button'
import FileForm from '../components/forms/FileForm'
import InputSideLabelForm from '../components/forms/InputSideLabel'
import Select2Form from '../components/forms/Select2'
import SideLabel from '../components/forms/SideLabel'
import ManageCreateLayout from '../components/layout/ManageCreateLayout'
import ImageList from '../components/lists/ImageList'
import useAuth from '../hooks/auth.hook'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import {
  CreatePlaceIconRequest,
  UpdatePlaceIconRequest,
} from '../types/api/place-icon'
import { ImageFileModel } from '../types/File'
import PlaceIcon from '../types/PlaceIcon'
import {
  PlaceIconCreateValidation,
  PlaceIconUpdateValidation,
} from '../validations/place-icon.validation'

function PlaceIconCreate() {
  const navigate = useNavigate()
  const { id } = useParams()
  const methods = useForm({
    resolver: joiResolver(
      id ? PlaceIconUpdateValidation : PlaceIconCreateValidation
    ),
  })
  const [placeIcon, setPlaceIcon] = useState<PlaceIcon>()
  const [files, setFiles] = useState<ImageFileModel[]>([])
  const { isAdmin } = useAuth()
  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (id) {
      await updatePlaceIconApi(id, formData as UpdatePlaceIconRequest)
    } else {
      await createPlaceIconApi(formData as CreatePlaceIconRequest)
    }
    navigate('/place-icon')
  }

  const handleDeleteButton = async () => {
    if (!id) return
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          await deletePlaceIconApi(id)
          navigate('/place-icon')
        },
      },
    })
  }

  useEffect(() => {
    if (id) {
      getPlaceIconApi(id).then((item) => {
        methods.setValue('iconName', item.iconName)
        methods.setValue('category', item.category)
        setFiles([item])
        setPlaceIcon(item)
      })
    }
  }, [id, methods])
  return (
    <ManageCreateLayout
      title="아이콘 생성"
      description="장소, 안내판에 사용할 아이콘을 관리하는 페이지 입니다."
      methods={methods}
      onSubmit={handleSubmit}
      cancelButton={
        <Link to="/sign-board" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      saveButton={
        <Button submit className={PrimaryButtonStyle}>
          저장
        </Button>
      }
    >
      {id && (
        <SideLabel id="id" label="ID">
          {id}
        </SideLabel>
      )}
      <SideLabel id="category" label="카테고리">
        <Select2Form id="category" name="category">
          <option value="pictogram">픽토그램</option>
          <option value="icon">아이콘</option>
        </Select2Form>
      </SideLabel>
      <InputSideLabelForm
        id="iconName"
        name="iconName"
        type="text"
        label="아이콘 이름"
      />
      <SideLabel id="file" label="이미지">
        <div className="flex flex-col gap-4">
          <ImageList items={files} />
          <FileForm
            id="file"
            name="file"
            type="file"
            onDropCallback={async (items: File[]) => {
              setFiles(items)
            }}
          />
        </div>
      </SideLabel>
      {placeIcon &&
        placeIcon.placeList?.map((item) => (
          <SideLabel label="아이콘이 사용된 장소">
            <ul>
              <li>
                <Link
                  to="/place/children/id"
                  className="text-teal-600 hover:text-teal-900"
                >
                  [업체 &gt; 건물 &gt; 층] 어디 장소
                </Link>
              </li>
            </ul>
          </SideLabel>
        ))}
    </ManageCreateLayout>
  )
}

export default PlaceIconCreate
