import errorMessage from '../langs/errors.json'

interface MessageOptions {
  matches?: RegExp
  value?: string
  label?: string
}

export default function lang() {
  const message: { [key: string]: string } = errorMessage
  const getMessage = (key: string, options?: MessageOptions): string => {
    if (options) {
      const { matches, value } = options
      if (matches && value) {
        return message[key].replace(matches, value) || ''
      }
    }
    return message[key] || ''
  }
  return { getMessage }
}
