import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import { getDocumentsApi, getTempDocumentsApi } from '../api/document.api'
import { ADMIN_TITLE } from '../constants/title'
import { DocumentModel } from '../types/Document'
import Paginate from '../components/paginate'
import ManageListLayout from '../components/layout/ManageListLayout'
import { PrimaryButtonStyle } from '../styles/button.style'
import SearchInput from '../components/inputs/SearchInput'
import { customerAtom } from '../recoil/customer.recoil'
import { CustomerSelector } from '../components/survey/ListFilters'
import { classNames } from '../helpers/common.helper'
import {
  TableStyle,
  TheadStyle,
  ThStartStyle,
  ThEndStyle,
  TbodyStyle,
  TdEndStyle,
  ThStyle,
  TdStartStyle,
  TdStyle,
} from '../styles/table.style'
import Select from '../components/select/Select'
import SimpleAlert from '../components/notifications/SimpleAlert'

function DocumentList(): JSX.Element {
  const customer = useRecoilValue(customerAtom)
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const [temps, setTemps] = useState<DocumentModel[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)

  const getDocuments = useCallback(
    async (searchField?: string, keyword?: string) => {
      getDocumentsApi({ page, limit, searchField, keyword }, customer?.id).then(
        (data) => {
          setDocuments(data.items)
          setTotal(data.total)
        }
      )
    },
    [customer, page, limit]
  )

  const getTemps = useCallback(async () => {
    if (!customer) return
    const result = await getTempDocumentsApi({ page: 1, limit: 1 }, customer.id)
    setTemps(result.items)
  }, [customer])

  const handleOnChangePage = (p: number) => {
    setPage(p)
  }

  const handleOnSearch = (searchField?: string, keyword?: string) => {
    getDocuments('nameOrCode', keyword)
  }

  useEffect(() => {
    document.title = `문서 관리- ${ADMIN_TITLE}`
    getTemps()
    getDocuments()
  }, [page, getDocuments, getTemps])
  return (
    <ManageListLayout
      title="문서 관리"
      description="문서를 관리하는 페이지 입니다."
      createButtonChildren={
        <Link to="/documents/create" className={PrimaryButtonStyle}>
          문서 생성하기
        </Link>
      }
      searchChildren={
        <SearchInput className="w-30" onSearch={handleOnSearch} />
      }
      paginateChildren={
        <Paginate
          total={total}
          limit={limit}
          currentPage={page}
          onChangePage={handleOnChangePage}
        />
      }
    >
      <div className="flex flex-col sm:flex-col sm:justify-between sm:items-end md:flex-row gap-2 pb-2">
        <div className="flex flex-row gap-2">
          <CustomerSelector />
          <Select
            label="페이지당 건수"
            options={['5', '10', '20', '50', '100'].map((item) => ({
              text: item,
              value: item,
            }))}
            defaultValue={20}
            onChange={(e) => {
              setLimit(Number(e.target.value))
              setPage(1)
            }}
          />
        </div>
        <div className="text-slate-700">전체 수 : {total}</div>
      </div>
      {temps.map((item) => (
        <SimpleAlert className="mb-2" key={item.id}>
          작성중인 문서가 있습니다.{' '}
          <Link
            className="text-teal-600 hover:text-teal-900"
            to={`/documents/temp/${item.id}`}
          >
            계속하기
          </Link>
        </SimpleAlert>
      ))}
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className={classNames(TableStyle)}>
              <thead className={classNames(TheadStyle)}>
                <tr>
                  <th scope="col" className={classNames(ThStartStyle)}>
                    번호
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    문서명
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    업체
                  </th>
                  <th scope="col" className={classNames(ThStyle)}>
                    생성일
                  </th>
                  <th scope="col" className={classNames(ThEndStyle)}>
                    <span className="sr-only">기능</span>
                  </th>
                </tr>
              </thead>
              <tbody className={classNames(TbodyStyle)}>
                {documents.length === 0 && (
                  <tr>
                    <td colSpan={4} className={classNames(TdStartStyle)}>
                      등록된 데이터가 없습니다
                    </td>
                  </tr>
                )}
                {documents.map((doc, i: number) => (
                  <tr key={doc.id}>
                    <td className={classNames(TdStartStyle)}>
                      {(page - 1) * limit + i + 1}
                    </td>
                    <td className={classNames(TdStyle)}>{doc.title}</td>
                    <td className={classNames(TdStyle, 'sm:pl-6')}>
                      {doc.customer?.name}
                    </td>
                    <td className={classNames(TdStyle)}>
                      {dayjs(doc.createdAt).format('YYYY-MM-DD')}
                    </td>
                    <td
                      className={classNames(
                        TdEndStyle,
                        'flex justify-end space-x-4'
                      )}
                    >
                      <a
                        href={`${process.env.REACT_APP_SCAN_URL}/document/${doc.id}`}
                        className="text-teal-600 hover:text-teal-900"
                        target="_blank"
                        rel="noreferrer"
                      >
                        상세<span className="sr-only">, {doc.title}</span>
                      </a>
                      <Link
                        to={`/documents/edit/${doc.id}`}
                        className="text-teal-600 hover:text-teal-900"
                      >
                        수정<span className="sr-only">, {doc.title}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManageListLayout>
  )
}

export default DocumentList
