import { joiResolver } from '@hookform/resolvers/joi'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import React, { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteQrApi, getQrApi } from '../api/qr.api'
import Button from '../components/button/Button'
import { FinderItem } from '../components/finders/Finder'
import SideLabel from '../components/forms/SideLabel'
import ViewLayout from '../components/layout/ViewLayout'
import { showAlert } from '../lib/alert'
import {
  DefaultButtonStyle,
  PrimaryButtonStyle,
  SecondsButtonStyle,
} from '../styles/button.style'
import { Qr } from '../types/Qr'

function QrView(): JSX.Element {
  const { id } = useParams()
  const [qr, setQr] = useState<Qr>()
  const navigate = useNavigate()
  const handleDeleteButton = async () => {
    showAlert({
      title: '정말로 삭제 하시겠습니까?',
      confirm: {
        confirmClick: async () => {
          if (id) await deleteQrApi(id)
          navigate('/qr')
        },
      },
    })
  }
  const loadData = useCallback(async () => {
    if (id) {
      await getQrApi(id).then((data) => setQr(data))
    }
  }, [id])

  useEffect(() => {
    if (id) {
      getQrApi(id).then((data) => {
        setQr(data)
      })
      loadData()
    }
  }, [loadData, id])

  return (
    <ViewLayout
      title="QR 상세"
      description="QR을 관리하는 페이지 입니다."
      cancelButton={
        <Link to="/qr" className={DefaultButtonStyle}>
          취소
        </Link>
      }
      delButton={
        id && (
          <Button onClick={handleDeleteButton} className={SecondsButtonStyle}>
            삭제
          </Button>
        )
      }
      editButton={
        <Link to={`/qr/edit/${id}`} className={PrimaryButtonStyle}>
          수정
        </Link>
      }
    >
      <SideLabel label="아이디">{qr?.id}</SideLabel>
      <SideLabel label="업체 선택">{qr?.customer?.name}</SideLabel>
      {qr?.document && (
        <SideLabel label="문서">{qr?.document?.title}</SideLabel>
      )}
      {qr?.place && <SideLabel label="장소">{qr?.place?.title}</SideLabel>}
      {qr?.link && <SideLabel label="링크">{qr?.link}</SideLabel>}
      <SideLabel label="QR">
        <a href={qr?.svg} target="_blank" download rel="noreferrer">
          <img className="w-28" src={`${qr?.svg}`} alt="" />
        </a>
        <a
          href={`${process.env.REACT_APP_SCAN_URL}/QR-${qr?.id}`}
          target="_blank"
          rel="noreferrer"
        >{`${process.env.REACT_APP_SCAN_URL}/QR-${qr?.id}`}</a>
      </SideLabel>
      <SideLabel label="제목">{qr?.title}</SideLabel>
      <SideLabel label="설명">
        <div className="whitespace-pre-line">{qr?.desc}</div>
      </SideLabel>
    </ViewLayout>
  )
}
export default QrView
